import React, { useEffect, useState, FC } from "react";
import { KeyedMutator } from "swr";
import { FormAdd } from "grommet-icons";

import AddButton from "~/components/UI/AddButton";
import updateStep from "~/lib/requests/updateStep";

import EditableTextArea from "../EditableTextArea";
import { Prompt } from "./StepCanvas.styles";
import { textForLocale } from "~/lib/textForLocale";

interface PromptEditorProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  section: FlowSection;
  step: ContentStep | ChoicesStep | IframeStep | FeedbackScoreStep | FeedbackWordsStep;
  viewMode: Roles;
  locale: string;
}

const PromptEditor: FC<PromptEditorProps> = ({ flow, mutate, section, step, viewMode, locale }) => {
  const [showNewPrompt, setShowNewPrompt] = useState(false);

  useEffect(() => {
    setShowNewPrompt(false);
  }, [step]);

  return (
    <>
      {!textForLocale(step.content[viewMode].text, locale) && !showNewPrompt ? (
        <AddButton onClick={() => setShowNewPrompt(true)}>
          <FormAdd />
          {step.type === "IframeStep" ? "Add Customised Loading Text" : "Add Text Prompt"}
        </AddButton>
      ) : (
        <Prompt>
          <EditableTextArea
            key={viewMode}
            initiallyEditing={showNewPrompt}
            initialValue={textForLocale(step.content[viewMode].text, locale) || ""}
            valueTransformer={(value: string) => ({
              content: {
                [viewMode]: {
                  text: {
                    ...step.content[viewMode].text,
                    [locale]: value,
                  },
                },
              },
            })}
            onCommit={(value) => {
              // @ts-ignore
              updateStep(mutate, flow.id, section.id, step.id, value);
            }}
          />
        </Prompt>
      )}
    </>
  );
};

export default PromptEditor;
