class BaseError extends Error {}
class RequestError extends BaseError {
  constructor(public status: number, public message: string, public body: string | unknown) {
    super();
  }
}

const fetcher = async (url: string) => {
  let res: Response | undefined;
  try {
    res = await fetch(url, { cache: "no-cache", credentials: "include" });

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error = new RequestError(res.status, "The server replied with an error.", "");

      throw error;
    }
  } catch (error) {
    if (error instanceof RequestError) {
      throw error;
    }
    const e = new RequestError(0, "Unable to contact the server.", error);

    throw e;
  }

  try {
    const text = await res.text();
    const json = JSON.parse(text);
    return json;
  } catch (error) {
    const e = new RequestError(600, "Unable to parse JSON.", error);
    throw e;
  }
};

const poster = async (url: string, data?: unknown) => {
  let res: Response | undefined;

  try {
    res = await fetch(url, {
      method: "POST",
      credentials: "include",

      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error = new RequestError(res.status, "The server replied with an error.", "");

      throw error;
    }
  } catch (error) {
    if (error instanceof RequestError) {
      throw error;
    }
    const e = new RequestError(0, "Unable to contact the server.", error);

    throw e;
  }

  try {
    const text = await res.text();
    const json = JSON.parse(text);
    return json;
  } catch (error) {
    const e = new RequestError(600, "Unable to parse JSON.", error);
    throw e;
  }
};

export { fetcher, poster };
