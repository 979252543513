import React, { FC } from "react";
import { Box } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface AutomationStepDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: AnyStep;

  viewMode: Roles;
}

const AutomationStepDetails: FC<AutomationStepDetailsProps> = () => {
  return (
    <>
      <Label>No properties yet</Label>
      No properties for this type of step. If you can think of something you&apos;d like to configure for this type of
      step, let me know!
    </>
  );
};

export default AutomationStepDetails;
