import styled from "styled-components";

const AddButton = styled.button`
  align-items: center;
  background-color: #fff;
  border-radius: 7px;
  border: 1px dashed #bbb;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-top: 10px;
  padding: 4px;
  transition: all 0.2s;
  width: 100%;

  &.spacing {
    margin-right: 10px;
  }

  &:hover {
    background-color: #f4f4f4;
  }

  &:active {
    background-color: #fff;
    box-shadow: 0px 0px 0px 2px #eee;
  }

  &.disabled {
    border: 1px dashed #ccc;
    background-color: #fff;
    cursor: not-allowed;
  }

  &.add-language {
    width: 95%;
    margin-top: 0px;
  }
`;

export const AddAudioButton = styled.label`
  align-items: center;
  background-color: #fff;
  border-radius: 7px;
  border: 1px dashed #bbb;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-top: 10px;
  padding: 4px;
  transition: all 0.2s;
  width: 100%;

  &.spacing {
    margin-right: 10px;
  }

  &:hover {
    background-color: #f4f4f4;
  }

  &:active {
    background-color: #fff;
    box-shadow: 0px 0px 0px 2px #eee;
  }
`;

export default AddButton;
