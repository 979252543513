import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "~/components/UI";
import { useNavigate } from "react-router-dom";
import { KeyedMutator } from "swr";
import FlowSettings from "./Modals/FlowSettings";
import CustomContent from "./Modals/CustomContent";
import { DEFAULT_LANGUAGE } from "~/lib/defaults";
import LanguageSelector from "../UI/LanguageSelector";
import { Console, Language, Magic, Previous, Redo, Undo } from "grommet-icons";
import JSONEdit from "./Modals/JSONEdit";
import { toast, Slide } from "react-toastify";
import fixMissingTranslations, { TranslationRequest } from "~/lib/requests/fixMissingTranslations";
import { Spinner } from "grommet";
import updateStep from "~/lib/requests/updateStep";

const BackLink = styled(Link)`
  align-items: center;
  color: #555;
  display: flex;
  font-size: 14px;

  &:hover {
    color: #000;
  }
`;

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLine = styled.div`
  border-left: 1px solid #ccc;
  height: 20px;
  margin: 0 10px;
`;

const StyledIconButtons = styled(Button)`
  background: #eee;
  max-height: 32px;

  svg {
    stroke: none;
  }
`;

type LanguageOptions = { label: string; value: string; translated?: boolean };

interface HeaderProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  setSelectedLocale: (locale: string) => void;
  selectedLocale: string;
  setSelectedSection: (section: FlowSection | undefined) => void;
  setSelectedStep: (arg: { sectionID: number; stepID: number } | undefined) => void;
  languages: Language[] | undefined;
  historyIndex: number;
  history: any[];
  undo: () => { message: string; sectionId?: number; stepId?: number; locale: string };
  redo: () => { message: string; sectionId?: number; stepId?: number; locale: string };
  appViewMode: "canvas" | "outline";
  setAppViewMode: (mode: "canvas" | "outline") => void;
}

const Header: FC<HeaderProps> = ({
  flow,
  mutate,
  setSelectedLocale,
  selectedLocale,
  setSelectedSection,
  setSelectedStep,
  languages,
  undo,
  redo,
  history,
  historyIndex,
  appViewMode,
  setAppViewMode,
}) => {
  const navigate = useNavigate();
  const initialLocale = languages?.find((language) => language.code === selectedLocale)?.name;
  const [showFlowSettings, setShowFlowSettings] = useState(false);
  const [showCustomContent, setShowCustomContent] = useState(false);
  const [locale, setLocale] = useState(initialLocale || DEFAULT_LANGUAGE);
  const [showJSONView, setShowJSONView] = useState(false);

  const [fixTranslationsLoading, setFixTranslationsLoading] = useState(false);

  const languageOptions = languages?.map((language) => ({
    label: language.name,
    value: language.code,
  })) as LanguageOptions[];

  const handleUndo = (backwards: boolean) => {
    const undoResult = backwards ? undo() : redo();

    toast(undoResult.message, {
      transition: Slide,
      autoClose: 2500,
      hideProgressBar: true,
      closeButton: true,
      position: "top-center",
    });

    const sectionId = undoResult.sectionId;
    const stepId = undoResult.stepId;
    const localeUndo = undoResult.locale;
    if (localeUndo) {
      const localeNameUndo = languages?.find((language) => language.code === localeUndo)?.name;
      setSelectedLocale(localeUndo);
      setLocale(localeNameUndo || DEFAULT_LANGUAGE);
    }

    if (sectionId && stepId) {
      navigate(`/session-flow/${flow.id}/section/${sectionId}/step/${stepId}`, {
        replace: true,
      });
      setSelectedSection(undefined);
      setSelectedStep({ sectionID: sectionId, stepID: stepId });
    } else if (sectionId) {
      const section = flow.sections.find((x: { id: number }) => x.id === sectionId);
      navigate(`/session-flow/${flow.id}/section/${sectionId}`, { replace: true });
      setSelectedSection(section);
      setSelectedStep(undefined);
    }
  };

  const backToLink =
    process.env.NODE_ENV === "production"
      ? `https://schedule.warmspace.io/manage-flows/edit/${flow.scheduleAppFlowId}`
      : `http://localhost:8080/manage-flows/edit/${flow.scheduleAppFlowId}`;

  return (
    <HeaderWrapper>
      {showFlowSettings && (
        <FlowSettings mutate={mutate} flow={flow} visible={!!showFlowSettings} setVisible={setShowFlowSettings} />
      )}

      {showCustomContent && (
        <CustomContent
          mutate={mutate}
          flow={flow}
          visible={!!showCustomContent}
          setVisible={setShowCustomContent}
          locale={selectedLocale}
          languages={languages}
          appViewMode={appViewMode}
        />
      )}

      {showJSONView && <JSONEdit mutate={mutate} flow={flow} visible={!!showJSONView} setVisible={setShowJSONView} />}

      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <BackLink to={backToLink} aria-label="Back to manage flows" title="Back to manage flows">
          <Previous size="18px" />
        </BackLink>
        <h1>
          &nbsp;
          {flow.name} &nbsp;
          <LanguageSelector
            languages={languages}
            locale={locale}
            setLocale={setLocale}
            setSelectedLocale={setSelectedLocale}
            flow={flow}
            languageOptions={languageOptions}
            mutate={mutate}
          />
        </h1>
      </div>
      <HeaderActions>
        &nbsp;
        {appViewMode === "outline" && (
          <>
            <Button
              secondary
              disabled={fixTranslationsLoading}
              label={
                <>
                  {fixTranslationsLoading ? (
                    <Spinner margin={{ right: "10px" }} size="xsmall" color={"#666"} />
                  ) : (
                    <Magic size={"18px"} />
                  )}
                  Fix missing Translations
                </>
              }
              size="small"
              onClick={async () => {
                setFixTranslationsLoading(true);

                // Find missing translations
                const missingTranslations: TranslationRequest = {};
                let stepIndex = 0;
                flow.sections.forEach((section) => {
                  section.steps.forEach((step) => {
                    stepIndex++;

                    ["A", "B", "EVERYONE_ELSE"].forEach((role) => {
                      flow.translatedLanguages.forEach((language) => {
                        if (step.type !== "ContentStep") return; // Skip if not a content step (only content steps have text to translate
                        if (language === "en") return; // Skip english, that's the source language.
                        if (step.content[role as Roles].text[language]) return; // Skip if already translated.
                        if (step.shareContent && role !== "EVERYONE_ELSE") return; // Skip if shared content (only need to translate once

                        const translationKey = `${step.id}-text-${role}`;

                        missingTranslations[translationKey] = missingTranslations[translationKey] || {
                          sourceText: "",
                          targetLanguages: [],
                        };

                        missingTranslations[translationKey].sourceText = step.content[role as Roles].text["en"];
                        missingTranslations[translationKey].targetLanguages.push(language);

                        if (step.content[role as Roles].actions) {
                          step.content[role as Roles].actions.forEach((action, index) => {
                            if (action.buttonText[language]) return; // Skip if already translated.

                            const actionTranslationKey = `${step.id}-action-${role}-${index}`;

                            missingTranslations[actionTranslationKey] = missingTranslations[actionTranslationKey] || {
                              sourceText: "",
                              targetLanguages: [],
                            };

                            missingTranslations[actionTranslationKey].sourceText = action.buttonText["en"];
                            missingTranslations[actionTranslationKey].targetLanguages.push(language);
                          });
                        }
                      });
                    });
                  });
                });

                const translationResponse = await fixMissingTranslations(flow.id, missingTranslations);
                mutate();
                setFixTranslationsLoading(false);
              }}
            />
            <StyledLine />
          </>
        )}
        <StyledIconButtons
          label={<Undo size={"18px"} />}
          size="small"
          onClick={() => {
            handleUndo(true);
          }}
          data-testid="undo"
          disabled={historyIndex === 0}
        />
        &nbsp;
        <StyledIconButtons
          label={<Redo size={"18px"} />}
          size="small"
          onClick={() => {
            handleUndo(false);
          }}
          data-testid="redo"
          disabled={historyIndex >= history.length}
        />
        <StyledLine />
        <Button
          secondary
          label="Canvas"
          size="small"
          onClick={() => {
            setAppViewMode("canvas");
          }}
          style={{
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
            backgroundColor: appViewMode === "canvas" ? "#aaa" : "",
          }}
        />
        <Button
          secondary
          label="Outline"
          size="small"
          onClick={() => {
            setAppViewMode("outline");
          }}
          style={{
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            backgroundColor: appViewMode === "outline" ? "#aaa" : "",
          }}
        />
        <StyledLine />
        <StyledIconButtons
          label={<Console size={"18px"} />}
          size="small"
          onClick={() => {
            setShowJSONView(true);
          }}
          data-testid="json-view"
        />
        <StyledLine />
        <Button
          secondary
          label="Custom Data"
          size="small"
          onClick={() => {
            setShowCustomContent(true);
          }}
        />
        &nbsp;
        <Button
          secondary
          label="Flow Settings"
          size="small"
          onClick={() => {
            setShowFlowSettings(true);
          }}
        />
        &nbsp;
        <Button
          primary
          label="Preview Flow"
          size="small"
          disabled={!flow.sections.some((section) => section.steps.length > 0)}
          onClick={() => {
            navigate(selectedLocale + "/preview");
          }}
        />
        &nbsp;
      </HeaderActions>
    </HeaderWrapper>
  );
};

export default Header;
