import React, { FC, useEffect, useMemo } from "react";
import { KeyedMutator } from "swr";

import {
  isContentStep,
  isRoleRotationStep,
  isChoicesStep,
  isGoToStep,
  isBranchStep,
  isIframeStep,
  isMarkStepComplete,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
} from "~/lib/type_guards";

import { Preview, PreviewBox } from "./StepCanvas.styles";
import ChoicesStep from "./ChoicesStep";
import ContentStep from "./ContentStep";
import BranchStep from "./BranchStep";
import NoStep from "./NoStep";
import RoleRotationStep from "./RoleRotationStep";
import GoToStep from "./GoToStep";
import ViewModeToggle from "./ViewModeToggle";
import { ROLE_A, EVERYONE_ELSE } from "~/lib/defaults";
import IframeStep from "./IframeStep";
import { textForLocale } from "~/lib/textForLocale";
import MarkStepComplete from "./MarkStepComplete";
import FeedbackScoreStep from "./FeedbackScoreStep";
import FeedbackWordsStep from "./FeedbackWordsStep";

interface StepCanvasProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  section: FlowSection | undefined;
  setViewMode: React.Dispatch<React.SetStateAction<Roles>>;
  selectedChoice: number;
  setSelectedChoice: React.Dispatch<React.SetStateAction<number>>;

  selectedAction: number;
  setSelectedAction: React.Dispatch<React.SetStateAction<number>>;
  step: AnyStep | undefined;

  viewMode: Roles;
  locale: string;
  languages: Language[] | undefined;
}

const StepCanvas: FC<StepCanvasProps> = ({
  flow,
  mutate,
  section,
  setViewMode,
  step,
  viewMode,
  selectedChoice,
  setSelectedChoice,
  selectedAction,
  setSelectedAction,
  locale,
  languages,
}) => {
  useEffect(() => {
    if (
      (isContentStep(step) ||
        isChoicesStep(step) ||
        isFeedbackScoreStep(step) ||
        isFeedbackWordsStep(step) ||
        isIframeStep(step)) &&
      step.shareContent
    ) {
      setViewMode(EVERYONE_ELSE);
    } else {
      setViewMode(ROLE_A);
    }
    setSelectedChoice(-1);
    setSelectedAction(-1);
  }, [step?.id]);

  const selectedLocale = languages?.find((language) => language.code === locale)?.name;

  const flattenedSteps = useMemo(() => flow.sections.flatMap((s) => s.steps), [flow]);
  const isLastStep = useMemo(() => flattenedSteps[flattenedSteps.length - 1]?.id === step?.id, [flattenedSteps, step]);
  return (
    <Preview
      onClick={() => {
        setSelectedChoice(-1);
        setSelectedAction(-1);
      }}
    >
      <small>
        {step && section
          ? textForLocale(section.title, locale) +
            " > " +
            textForLocale(step.title, locale) +
            " (you are editing the version in " +
            selectedLocale +
            " of this flow)"
          : "No step selected"}
      </small>
      <PreviewBox className={step ? "step-selected" : ""}>
        {isContentStep(step) && section && (
          <ContentStep
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            flow={flow}
            section={section}
            step={step}
            mutate={mutate}
            viewMode={viewMode}
            isLastStep={isLastStep}
            locale={locale}
          />
        )}

        {isChoicesStep(step) && section && (
          <ChoicesStep
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
            flow={flow}
            section={section}
            step={step}
            mutate={mutate}
            viewMode={viewMode}
            locale={locale}
          />
        )}
        {isIframeStep(step) && section && (
          <IframeStep flow={flow} mutate={mutate} section={section} step={step} viewMode={viewMode} locale={locale} />
        )}

        {isBranchStep(step) && section && <BranchStep />}
        {isRoleRotationStep(step) && section && <RoleRotationStep />}
        {isGoToStep(step) && section && <GoToStep />}
        {isMarkStepComplete(step) && section && <MarkStepComplete />}
        {isFeedbackScoreStep(step) && section && (
          <FeedbackScoreStep
            flow={flow}
            mutate={mutate}
            section={section}
            step={step}
            viewMode={viewMode}
            locale={locale}
          />
        )}
        {isFeedbackWordsStep(step) && section && (
          <FeedbackWordsStep
            flow={flow}
            mutate={mutate}
            section={section}
            step={step}
            viewMode={viewMode}
            locale={locale}
          />
        )}

        {!step && <NoStep />}
      </PreviewBox>
      {(isContentStep(step) ||
        isChoicesStep(step) ||
        isIframeStep(step) ||
        isFeedbackScoreStep(step) ||
        isFeedbackWordsStep(step)) && <ViewModeToggle step={step} viewMode={viewMode} setViewMode={setViewMode} />}
    </Preview>
  );
};

export default StepCanvas;
