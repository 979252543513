import React, { FC } from "react";
import { Button } from "~/components/UI";
import { Layer, Card, CardBody, CardFooter, Select, CardHeader, Text } from "grommet";
import styled from "styled-components";
import { LanguageOptions } from "~/components/UI/LanguageSelector";
import updateFlow from "~/lib/requests/updateFlow";
import { KeyedMutator } from "swr";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  untranslatedLanguages: LanguageOptions[] | undefined;
  setSelectedLocale: (locale: string) => void;
  setLocale: (locale: string) => void;
  setTranslatedLanguages: (locales: string[]) => void;
  translatedLanguages: string[];
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
}

const Form = styled.form`
  font-size: 14px;
`;

const AddLanguage: FC<Props> = ({
  visible,
  setVisible,
  untranslatedLanguages,
  setSelectedLocale,
  setLocale,
  setTranslatedLanguages,
  translatedLanguages,
  flow,
  mutate,
}) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState<LanguageOptions | undefined>(undefined);
  const [filteredLanguages, setFilteredLanguages] = React.useState<LanguageOptions[] | undefined>(
    untranslatedLanguages
  );
  const createCustomData = (locale: string) => {
    if (flow.data && !flow.data[locale]) {
      flow.data[locale] = flow.data["en"];
    }
  };
  const handleAddLanguage = () => {
    const newTranslatedLocales = [...translatedLanguages];
    newTranslatedLocales.push(selectedLanguage?.value as string);
    setTranslatedLanguages(newTranslatedLocales);

    updateFlow(flow, { translatedLanguages: newTranslatedLocales }, mutate);

    setSelectedLocale(selectedLanguage?.value as string);
    setLocale(selectedLanguage?.label as string);
    createCustomData(selectedLanguage?.value as string);

    setVisible(false);
  };

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            Add a Language
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "30px", top: "small" }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label>Select a New Language</label>
            <br />
            <Select
              id="language-select"
              options={filteredLanguages || []}
              value={selectedLanguage}
              labelKey="label"
              autoFocus
              onOpen={() => {
                setFilteredLanguages(untranslatedLanguages);
              }}
              onChange={({ option }) => {
                setSelectedLanguage(option);
              }}
              dropAlign={{ top: "top", left: "left" }}
              dropHeight="small"
              disabled={!untranslatedLanguages}
              placeholder="Select a language to add"
              onSearch={(text) => {
                // The line below escapes regular expression special characters:
                // [ \ ^ $ . | ? * + ( )
                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

                // Create the regular expression with modified value which
                // handles escaping special characters. Without escaping special
                // characters, errors will appear in the console
                const exp = new RegExp(escapedText, "i");

                // Filter the languages based on the search text
                setFilteredLanguages(untranslatedLanguages?.filter((l) => exp.test(l.label)));
              }}
            />
          </Form>
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

          <Button primary size="small" label="Add Language" onClick={handleAddLanguage} />
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default AddLanguage;
