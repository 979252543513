/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC } from "react";
import { PreviewInner } from "./StepCanvas.styles";
import { Directions } from "grommet-icons";
import styled from "styled-components";

const AutomationStepInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: auto;
`;

interface BranchStepProps {}

const BranchStep: FC<BranchStepProps> = () => {
  return (
    <PreviewInner>
      <AutomationStepInfo>
        <Directions />
        <br />
        <small>Branch Step</small>
        The branch step skips a step based on a rule.
        <br /> If the rule is met, it skips a step. If the rule is not met, it goes on to the next step.
      </AutomationStepInfo>
    </PreviewInner>
  );
};

export default BranchStep;
