import { isContentStep, isChoicesStep, isFeedbackScoreStep } from "~/lib/type_guards";
import { ROLES, EVERYONE_ELSE } from "~/lib/defaults";
import { User, StepState } from "./FlowPreview";

// countActions takes a step and figures out how many actions are on that step
// this is used to figure out if a step is done or not.
export const countActions = (step: AnyStep, userCount: number) => {
  let actionCount = 0;

  // Determine number of actions on this step for shared content choices step
  if (isChoicesStep(step) && step.shareContent) {
    if (step.content[EVERYONE_ELSE]) {
      const content = step.content[EVERYONE_ELSE];
      if (content.choices && content.choices.length > 0) {
        actionCount = userCount;
      }
    }
  }

  // Determine number of actions on this step for shared content feedback score step
  if (isFeedbackScoreStep(step) && step.shareContent) {
    if (step.content[EVERYONE_ELSE]) {
      if (step.content[EVERYONE_ELSE].maxValue) {
        actionCount = userCount;
      }
    }
  }

  // Determine number of actions on this step for shared content
  if (isContentStep(step) && step.shareContent) {
    if (step.content[EVERYONE_ELSE]) {
      if (step.content[EVERYONE_ELSE].actions.length > 0 || step.audioFile) {
        actionCount = userCount;
      }
    }
  }

  // Determine number of actions on this step for not-shared content choices step
  if (isChoicesStep(step) && !step.shareContent) {
    ROLES.forEach((role) => {
      if (step.content[role]) {
        const content = step.content[role];
        if (content.choices && content.choices.length > 0) {
          actionCount += 1;
        }
      }
    });
  }

  // Determine number of actions on this step for not-shared content feedback score step
  if (isFeedbackScoreStep(step) && !step.shareContent) {
    ROLES.forEach((role) => {
      if (step.content[role]) {
        const content = step.content[role];
        if (content.maxValue) {
          actionCount += 1;
        }
      }
    });
  }

  // Determine number of actions on this step for not-shared content
  if (isContentStep(step) && !step.shareContent) {
    ROLES.forEach((role) => {
      if (step.content[role]) {
        const content = step.content[role];
        if (content.actions && content.actions.length > 0) {
          actionCount += 1;
        }
      }
    });
  }

  return actionCount;
};

// countDone counts how many 'done' actions have been made on a step.
export const countDone = (
  step: ContentStep | ChoicesStep | FeedbackScoreStep,
  stepIndex: number,
  users: User[],
  flowState: StepState[]
) => {
  let doneCount = 0;

  if (step.shareContent) {
    // If the step shares its content with everyone, then the amount of done
    // actions is just the number of times it has been marked done by any user.
    doneCount = Object.values(users).filter((u) => flowState[stepIndex][u.id]).length;
  } else {
    // If the step does Not share its content with everyone, then we only count
    // it as done if the person with the given role has completed the action
    // for their role. This lets us revisit a step with a new role assignment
    // and makes the system consider it incomplete.
    ROLES.forEach((role) => {
      let choices: Choice[] = [];

      if (isChoicesStep(step)) {
        choices = step.content[role].choices;
      }
      if (step.content[role].actions.length > 0 || (choices && choices?.length > 0)) {
        const userIDWithRole = users.find((u) => u.role === role)?.id;

        if (userIDWithRole) {
          if (flowState[stepIndex][userIDWithRole]) {
            doneCount += 1;
          }
        }
      }
    });

    if (isFeedbackScoreStep(step)) {
      ROLES.forEach((role) => {
        if (step.content[role].maxValue) {
          const userIDWithRole = users.find((u) => u.role === role)?.id;

          if (userIDWithRole) {
            if (flowState[stepIndex][userIDWithRole]) {
              doneCount += 1;
            }
          }
        }
      });
    }
  }

  return doneCount;
};

export function scaledDuration(baseFactor: number, numberOfPeople: number, duration: number) {
  const scalingFactor = baseFactor / numberOfPeople;
  return scalingFactor * duration;
}

export function bytesToHumanSize(bytes: number) {
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 B";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
}

export function millisecondsToStr(milliseconds: number) {
  function numberEnding(number: number) {
    return number > 1 ? "s" : "";
  }

  let temp = Math.floor(milliseconds / 1000);
  const years = Math.floor(temp / 31536000);
  if (years) {
    return years + " year" + numberEnding(years);
  }
  //TODO: Months! Maybe weeks?
  const days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + " day" + numberEnding(days);
  }
  const hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + " hour" + numberEnding(hours);
  }
  const minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + " minute" + numberEnding(minutes);
  }
  const seconds = temp % 60;
  if (seconds) {
    return seconds + " second" + numberEnding(seconds);
  }
  return "less than a second";
}
