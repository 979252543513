import React, { FC } from "react";
import { PreviewInner } from "./StepCanvas.styles";

const NoStep: FC = () => {
  return (
    <PreviewInner>
      <div style={{ margin: "auto" }}>
        <small>No step selected</small>
        <br />
        Please select a step to edit, or create a new one
      </div>
    </PreviewInner>
  );
};

export default NoStep;
