/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC } from "react";
import { PreviewInner } from "./StepCanvas.styles";
import { Waypoint } from "grommet-icons";
import styled from "styled-components";

const AutomationStepInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: auto;
`;

interface GoToStepProps {}

const GoToStep: FC<GoToStepProps> = () => {
  return (
    <PreviewInner>
      <AutomationStepInfo>
        <Waypoint />
        <br />
        <small>Go To Step</small>
        The &apos;go to&apos; step lets you move the flow to a step of your choosing.
        <br /> The flow will automatically proceed to the step configured in the right pane.
      </AutomationStepInfo>
    </PreviewInner>
  );
};

export default GoToStep;
