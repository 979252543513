import React from "react";
import { toast } from "react-toastify";

export default async (flow: FlowIndexResponse, sectionIDs: number[]) => {
  return fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flow.id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ sections: sectionIDs }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .catch(() => {
      toast(
        <>
          <b>Error re-ordering sections</b>
          <br />
          It was not possible to re-order sections due to an error. Please try again later or contact support.
        </>
      );
    });
};
