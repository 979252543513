import React, { FC } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import { Button } from "~/components/UI";

type Data = {
  [key: string]: {
    [key: string]: string;
  };
};

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  keyToDelete: string;
  flow: FlowIndexResponse;
  setData: (data: Data) => void;
  availableLocales: string[] | undefined;
}

const DeleteCustomData: FC<Props> = ({ visible, setVisible, flow, keyToDelete, setData, availableLocales }) => {
  if (!visible) return null;

  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            Delete &quot;{keyToDelete}&quot;
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "small" }}>
          <Text size="small">
            Are you sure you want to delete &quot;{keyToDelete}&quot;?
            <br />
            <br /> This custom tag and its content will be deleted from all languages.
          </Text>
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

          <Button
            primary
            size="small"
            label="Delete"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const newData = Object.assign({}, flow.data);
              availableLocales?.forEach((l) => {
                delete newData[l][keyToDelete];
              });
              setData(newData);
              setVisible(false);
            }}
          />
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default DeleteCustomData;
