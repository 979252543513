import React, { FC, useState, useEffect } from "react";
import DeleteSection from "./Modals/DeleteSection";
import DeleteStep from "./Modals/DeleteStep";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { Box } from "grommet";
import {
  FormAdd,
  CaretRightFill,
  CaretDownFill,
  Trash,
  Code,
  CheckboxSelected,
  Duplicate,
  BarChart,
  Contact,
} from "grommet-icons";
import EditableTextForLocale from "../UI/EditableTextForLocale";
import { KeyedMutator } from "swr";
import orderSections from "./ordering/orderSections";
import orderSteps from "./ordering/orderSteps";
import moveStep from "./ordering/moveStep";
import updateStepTitle from "~/lib/requests/updateStepTitle";
import deleteStep from "~/lib/requests/deleteStep";
import deleteSection from "~/lib/requests/deleteSection";
import updateSectionTitle from "~/lib/requests/updateSectionTitle";
import NewSection from "./Modals/NewSection";
import NewStep from "./Modals/NewStep";
import addStep from "~/lib/requests/addStep";
import addSection from "~/lib/requests/addSection";
import AddButton from "~/components/UI/AddButton";
import { useNavigate } from "react-router-dom";
import {
  isRoleRotationStep,
  isRoleRandomiserStep,
  isBranchStep,
  isGoToStep,
  isTakeTurnsStep,
  isChoicesStep,
  isContentStep,
  isIframeStep,
  isMarkStepComplete,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
} from "~/lib/type_guards";
import { Waypoint, Directions, Cycle, Refresh, Risk, Apps, Chat, Volume } from "grommet-icons";
import duplicateSection from "~/lib/requests/duplicateSection";
import duplicateStep from "~/lib/requests/duplicateStep";
import { Tooltip } from "react-tooltip";

const Wrapper = styled.div`
  flex-shrink: 0;
  width: 325px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const StepsPane = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  height: 100%;
  margin-right: 15px;
  overflow: scroll;
  padding: 12px;
`;

const SectionWrapper = styled.div`
  padding-bottom: 10px;
`;

const Section = styled.div`
  align-items: center;
  background-color: #eee;
  border-radius: 8px;
  border: 0px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 16px;
  padding-left: 8px;
  padding: 5px 10px;
  text-align: left;
  transition: box-shadow 0.2s;
  width: 100%;

  .deleteButton {
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }

  .duplicateButton {
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }

  &.step {
    margin-top: 10px;
  }

  &.branch {
    margin-left: 20px;
    width: auto;
  }

  &:hover {
    box-shadow: 0px 0px 0px 2px #eee;
    .deleteButton {
      opacity: 1;
      display: block;
    }
    .duplicateButton {
      opacity: 1;
      display: block;
    }
  }

  &.selected {
    background-color: #ddd;
  }

  &:active {
    box-shadow: 0px 0px 0px 0px #eee;
  }
`;

const ToggleButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  line-height: 0px;
  padding: 0px;

  &:hover {
    svg {
      fill: #000;
      stroke: #000;
    }
  }

  &.deleteButton {
    padding: 5px;
  }

  &.duplicateButton {
    padding: 5px;
  }
`;

const BranchLabel = styled.span`
  font-size: 12px;
  margin-right: 10px;
`;

const Steps = styled.div`
  padding-left: 30px;
  padding-bottom: 15px;
`;

const StyledTooltip = styled(Tooltip)`
  background-color: #666;
  color: #fff;
  font-size: 12px;
`;

interface SectionProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  setSelectedStep: (arg: { sectionID: number; stepID: number } | undefined) => void;
  selectedStep: { sectionID: number; stepID: number } | undefined;
  setSelectedSection: (section: FlowSection | undefined) => void;
  selectedSection: FlowSection | undefined;
  locale: string;
}

const Sections: FC<SectionProps> = ({
  flow,
  mutate,
  selectedSection,
  setSelectedSection,
  selectedStep,
  setSelectedStep,
  locale,
}) => {
  const navigate = useNavigate();
  const [openSections, setOpenSections] = useState<number[]>([]);
  const [showDeleteSectionDialog, setShowDeleteSectionDialog] = useState<false | { section: FlowSection }>(false);
  const [showDeleteStepDialog, setShowDeleteStepDialog] = useState<false | { step: AnyStep; section: FlowSection }>(
    false
  );
  const [newSectionDialogVisible, setNewSectionDialogVisible] = useState(false);
  const [newStepDialogVisible, setNewStepDialogVisible] = useState<false | number>(false);
  const [firstLoadComplete, setFirstLoadComplete] = useState<boolean>(false);

  useEffect(() => {
    if (!firstLoadComplete && selectedSection) {
      toggleSection(selectedSection.id);
      setFirstLoadComplete(true);
    }
  }, [selectedSection, firstLoadComplete]);

  const toggleSection = (sectionID: number) => {
    const newOpenSections = [...openSections];

    const index = newOpenSections.indexOf(sectionID);
    if (index > -1) {
      newOpenSections.splice(index, 1);
    } else {
      newOpenSections.push(sectionID);
    }

    setOpenSections(newOpenSections);
  };

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    // Skip if dragging to nowhere or to the same spot.
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    const start = source.droppableId;
    const finish = destination.droppableId;

    // Sections
    if (type == "sections") {
      const draggableIdNumber = Number(draggableId.replace("section-", ""));
      await orderSections(flow, source.index, destination.index, draggableIdNumber, mutate);
      return;
    }

    // Steps - re-ordering steps in same section
    const draggableIdNumber = Number(draggableId.replace("step-", ""));
    if (type == "steps" && start === finish) {
      const sectionID = Number(start.replace("section-", ""));
      const section = flow.sections.find((s) => s.id === sectionID);

      if (!section) return; // TODO - Raise an error?

      await orderSteps(flow, section, source.index, destination.index, draggableIdNumber, mutate);
      return;
    }

    // Steps - Moving a step to another section
    const fromSectionID = Number(start.replace("section-", ""));
    const fromSection = flow.sections.find((s) => s.id === fromSectionID);

    const toSectionID = Number(finish.replace("section-", ""));
    const toSection = flow.sections.find((s) => s.id === toSectionID);

    const step = fromSection?.steps.find((s) => s.id === draggableIdNumber);

    const insertAtIndex = destination.index;

    if (!fromSection || !toSection || !step) return; // TODO - Raise an error?

    await moveStep(flow, fromSection, toSection, insertAtIndex, step, mutate, setSelectedStep);
  };
  return (
    <Wrapper>
      <small>Sections and Steps</small>
      <StepsPane>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"flow-" + flow.id} type="sections" direction="vertical">
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {flow.sections.map((section, i) => {
                    return (
                      <Draggable draggableId={`section-${section.id}`} index={i} key={section.id}>
                        {(provided) => {
                          return (
                            <SectionWrapper
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <Section
                                className={
                                  selectedSection && selectedSection.id === section.id ? "selected" : undefined
                                }
                                key={section.id}
                                onClick={() => {
                                  navigate("/session-flow/" + flow.id + "/section/" + section.id);
                                  setSelectedSection(section);
                                  setSelectedStep(undefined);
                                }}
                              >
                                <ToggleButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    toggleSection(section.id);
                                  }}
                                  id={`toggleSection-${section.id}`}
                                >
                                  {openSections.includes(section.id) ? <CaretDownFill /> : <CaretRightFill />}
                                </ToggleButton>

                                <EditableTextForLocale
                                  initialValue={section.title}
                                  onCommit={(_, value) => {
                                    updateSectionTitle(mutate, flow.id, section.id, value);
                                  }}
                                  id={section.id}
                                  locale={locale}
                                />
                                <ToggleButton
                                  className="duplicateButton"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    duplicateSection(mutate, flow.id, section.id);
                                  }}
                                  id={`duplicateSection-${section.id}`}
                                >
                                  <Duplicate size="small" />
                                </ToggleButton>

                                <ToggleButton
                                  className="deleteButton"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setShowDeleteSectionDialog({ section });
                                  }}
                                  id={`deleteSection-${section.id}`}
                                >
                                  <Trash size="small" />
                                </ToggleButton>
                              </Section>
                              {openSections.includes(section.id) && (
                                <Droppable droppableId={"section-" + section.id} type="steps" direction="vertical">
                                  {(provided) => {
                                    let branchCounter = 0;

                                    return (
                                      <Steps ref={provided.innerRef} {...provided.droppableProps}>
                                        {section.steps.map((step, index) => {
                                          if (isBranchStep(step)) {
                                            branchCounter = 4;
                                          }

                                          if (branchCounter > 0) {
                                            branchCounter = branchCounter - 1;
                                          }
                                          const count = branchCounter;
                                          return (
                                            <Draggable draggableId={`step-${step.id}`} index={index} key={step.id}>
                                              {(provided) => {
                                                return (
                                                  <Section
                                                    className={
                                                      (selectedStep?.stepID === step.id ? "selected step" : "step") +
                                                      (count > 0 && count < 3 ? " branch" : "")
                                                    }
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    onClick={() => {
                                                      navigate(
                                                        "/session-flow/" +
                                                          flow.id +
                                                          "/section/" +
                                                          section.id +
                                                          "/step/" +
                                                          step.id
                                                      );
                                                      setSelectedSection(undefined);
                                                      setSelectedStep({ sectionID: section.id, stepID: step.id });
                                                    }}
                                                  >
                                                    {count === 2 ? <BranchLabel>NO: </BranchLabel> : ""}
                                                    {count === 1 ? <BranchLabel>YES: </BranchLabel> : ""}
                                                    {isContentStep(step) && (
                                                      <>
                                                        {step.audioFile[locale] ? (
                                                          <Volume style={{ width: "21px", marginRight: "8px" }} />
                                                        ) : (
                                                          <Chat style={{ width: "21px", marginRight: "8px" }} />
                                                        )}
                                                      </>
                                                    )}

                                                    {isChoicesStep(step) && (
                                                      <Apps style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isRoleRotationStep(step) && (
                                                      <Cycle style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isRoleRandomiserStep(step) && (
                                                      <Risk style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isBranchStep(step) && (
                                                      <Directions style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isGoToStep(step) && (
                                                      <Waypoint style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isTakeTurnsStep(step) && (
                                                      <Refresh style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isIframeStep(step) && (
                                                      <Code style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isMarkStepComplete(step) && (
                                                      <CheckboxSelected style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isFeedbackScoreStep(step) && (
                                                      <BarChart style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    {isFeedbackWordsStep(step) && (
                                                      <Contact style={{ width: "21px", marginRight: "8px" }} />
                                                    )}

                                                    <EditableTextForLocale
                                                      initialValue={step.title}
                                                      onCommit={(_, value) => {
                                                        updateStepTitle(mutate, flow.id, section.id, step.id, value);
                                                      }}
                                                      id={step.id}
                                                      locale={locale}
                                                    />

                                                    <ToggleButton
                                                      className="duplicateButton"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        duplicateStep(mutate, flow.id, section.id, step.id);
                                                      }}
                                                      id={`duplicateStep-${step.id}`}
                                                    >
                                                      <Duplicate size="small" />
                                                    </ToggleButton>

                                                    <ToggleButton
                                                      className="deleteButton"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setShowDeleteStepDialog({ section, step });
                                                      }}
                                                      id={`deleteStep-${step.id}`}
                                                    >
                                                      <Trash size="small" />
                                                    </ToggleButton>
                                                  </Section>
                                                );
                                              }}
                                            </Draggable>
                                          );
                                        })}

                                        {provided.placeholder}
                                        {locale !== "en" && (
                                          <StyledTooltip
                                            id="warning-add-step"
                                            place="bottom"
                                            offset={5}
                                            positionStrategy="absolute"
                                            content="Move to English to add a step"
                                          />
                                        )}
                                        <Box direction="row" data-tooltip-id="warning-add-step">
                                          <AddButton
                                            onClick={() => setNewStepDialogVisible(section.id)}
                                            className={locale === "en" ? "" : "disabled"}
                                            disabled={locale !== "en"}
                                          >
                                            <FormAdd />
                                            Add a Step
                                          </AddButton>
                                        </Box>
                                      </Steps>
                                    );
                                  }}
                                </Droppable>
                              )}
                            </SectionWrapper>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        {locale !== "en" && (
          <StyledTooltip
            id="warning-add-section"
            place="bottom"
            offset={5}
            positionStrategy="absolute"
            content="Move to English to add a section"
          />
        )}
        <Box data-tooltip-id="warning-add-section">
          <AddButton
            onClick={() => setNewSectionDialogVisible(true)}
            className={locale === "en" ? "" : "disabled"}
            disabled={locale !== "en"}
          >
            <FormAdd />
            Add a Section
          </AddButton>
        </Box>
      </StepsPane>

      {showDeleteSectionDialog && (
        <DeleteSection
          deleteSection={deleteSection}
          flow={flow}
          section={showDeleteSectionDialog.section}
          visible={!!showDeleteSectionDialog}
          setVisible={setShowDeleteSectionDialog}
          mutate={mutate}
          locale={locale}
        />
      )}

      {showDeleteStepDialog && (
        <DeleteStep
          mutate={mutate}
          deleteStep={deleteStep}
          flow={flow}
          section={showDeleteStepDialog.section}
          step={showDeleteStepDialog.step}
          visible={!!showDeleteStepDialog}
          setVisible={setShowDeleteStepDialog}
          locale={locale}
        />
      )}
      <NewSection
        visible={newSectionDialogVisible}
        setVisible={setNewSectionDialogVisible}
        addSection={addSection.bind(undefined, mutate, flow.id)}
        locale={locale}
        currentFlow={flow}
        mutate={mutate}
      />
      <NewStep
        visible={!!newStepDialogVisible}
        setVisible={setNewStepDialogVisible}
        addStep={addStep.bind(undefined, mutate, setSelectedStep, flow.id)}
        sectionID={newStepDialogVisible}
        locale={locale}
      />
      <small style={{ marginTop: "10px" }}>v0.14.0  </small>
    </Wrapper>
  );
};

export default Sections;
