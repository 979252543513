import React, { FC } from "react";
import styled from "styled-components";
import { millisecondsToStr } from "./step_utils";

const StyledWaitSuggestion = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 10px;
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;

  a {
    color: #fff;
  }
`;

interface WaitSuggestionProps {
  locale: string;
  waitDuration: number;
  setTimeOnStep: (time: number) => void;
}

const WaitSuggestion: FC<WaitSuggestionProps> = ({ locale, waitDuration, setTimeOnStep }) => {
  const wait_messages = {
    en: "We suggest you take about " + `${millisecondsToStr(waitDuration * 1000)}` + " at this step. ",
    es: "Le sugerimos que tome aproximadamente " + `${millisecondsToStr(waitDuration * 1000)}` + " en este paso. ",
    pt: "Sugerimos que você leve cerca de " + `${millisecondsToStr(waitDuration * 1000)}` + " neste passo. ",
    fr: "Nous vous suggérons de prendre environ " + `${millisecondsToStr(waitDuration * 1000)}` + " à cette étape. ",
    de:
      "Wir empfehlen Ihnen, ungefähr " +
      `${millisecondsToStr(waitDuration * 1000)}` +
      " auf diesem Schritt zu nehmen. ",
    sv: " Vi föreslår att du tar ungefär" + `${millisecondsToStr(waitDuration * 1000)}` + " på denna steg. ",
    it: "Ti suggeriamo di prendere circa " + `${millisecondsToStr(waitDuration * 1000)}` + " a questo passo. ",
  } as LocaleText;

  const continue_early = {
    en: "Continue early.",
    es: "Continuar antes.",
    pt: "Continuar antes.",
    fr: "Continuez tôt.",
    de: "Früh fortfahren.",
    sv: "Fortsätt tidigt.",
    it: "Continua prima.",
  } as LocaleText;

  return (
    <StyledWaitSuggestion>
      {wait_messages[locale]}
      <a
        href="#"
        onClick={() => {
          setTimeOnStep(waitDuration + 1);
        }}
      >
        {continue_early[locale]}
      </a>
    </StyledWaitSuggestion>
  );
};

export default WaitSuggestion;
