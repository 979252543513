import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default (flowID: number, sectionID: number, stepID: number, mutate: KeyedMutator<FlowIndexResponse>) => {
  return fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/sections/${sectionID}/steps/${stepID}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => mutate())
    .catch(() => {
      toast(
        <>
          <b>Unable to delete step</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
        </>
      );
    });
};
