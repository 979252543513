function getTypeFunction(change: Change) {
  if (change.type === "step") {
    return getStepMessage;
  } else if (change.type === "section") {
    return getSectionMessage;
  } else {
    return getFlowMessage;
  }
}

const keysWithLocale = ["title", "text", "buttonText", "choices", "data"];
function localeGetter(change: Change) {
  if (keysWithLocale.includes(change.path[change.path.length - 2])) {
    return change.path[change.path.length - 1];
  } else if (keysWithLocale.includes(change.path[change.path.length - 3])) {
    // for custom data keys
    return change.path[change.path.length - 2];
  }
}

function getStepMessage(change: Change, newValue: any, flow: FlowIndexResponse | undefined) {
  const sectionId = change.sectionId;
  const stepId = change.stepId;
  const section = flow?.sections.find((s: { id: number }) => s.id === sectionId);
  const stepTitle = section?.steps.find((s: { id: number }) => s.id === stepId)?.title.en;
  const step = stepTitle ? `step "${stepTitle}"` : `step ${stepId}`;

  if (change.path[change.path.length - 2] === "title") {
    const locale = change.path[5];
    const newStepTitle = newValue.title[locale] || newValue.title["en"];
    return `Title on step ${stepId} was changed to "${newStepTitle}" in ${locale}`;
  } else if (change.path[8] && change.path[8] === "buttonText") {
    return `Button text on ${step} was changed`;
  } else if (change.path[6] && change.path[6] === "text") {
    return `Text prompt on ${step} was changed`;
  } else if (change.path[6] && change.path[6] === "choices") {
    return `Choices on ${step} were changed`;
  } else if (change.path[6] && change.path[6] === "actions") {
    return `Actions on ${step} were changed`;
  } else {
    const changedKey = change.path[change.path.length - 1];
    return `${changedKey} was changed in ${step}`;
  }
}

function getFlowMessage(change: Change, newValue: any) {
  const currentValue = newValue === change.from ? change.to : change.from;
  const removedSectionId = currentValue.sections.find((s: number) => !newValue.sections.includes(s));
  const addedSectionId = newValue.sections.find((s: number) => !currentValue.sections.includes(s));
  if (change.path[0] == "ordering section") {
    if (currentValue.sections.length > newValue.sections.length) {
      return `Section id: ${removedSectionId} was removed from the flow`;
    } else if (currentValue.sections.length < newValue.sections.length) {
      return `Section id: ${addedSectionId} was added to the flow`;
    } else return `The ordering of the sections was changed`;
  } else if (change.path[0] === "data") {
    const dataKey = change.path[change.path.length - 1] as string;
    const locale = change.path[1];
    return `Custom data key ${dataKey} for locale ${locale} was changed`;
  } else if (change.path[0] === "stepCount") {
    return;
  } else {
    const key = change.path[0];
    return `Flow ${key} was changed`;
  }
}

function getSectionMessage(change: Change, newValue: any, flow: FlowIndexResponse | undefined) {
  const sectionId = change.sectionId;
  const sectionTitle = flow?.sections.find((s: { id: number }) => s.id === sectionId)?.title.en;
  const section = sectionTitle ? `section "${sectionTitle}"` : `section ${sectionId}`;

  if (change.path[change.path.length - 2] === "title") {
    const locale = change.path[3];
    const newSectionTitle = newValue.title[locale] || newValue.title["en"];
    return `Title on section ${sectionId}  was changed to "${newSectionTitle}" in ${locale}`;
  } else if (change.path[0] === "ordering step") {
    const currentValue = newValue === change.from ? change.to : change.from;

    if (currentValue.steps.length > newValue.steps.length) {
      return `A step was removed from ${section}`;
    } else if (currentValue.steps.length < newValue.steps.length) {
      return `A step was added to ${section}`;
    } else {
      return `The ordering of the steps in ${section} was changed`;
    }
  } else {
    const changedKey = change.path[change.path.length - 1];
    const newKeyValue = newValue[changedKey];
    return `${changedKey} was changed to ${newKeyValue} in ${section}"`;
  }
}

/**
 * A function that will set the message that will be displayed in the toast
 * It will also set the sectionId and stepId if they are available
 * in case we need to navigate to a specific step or section
 *
 * @param changes - An array of changes made to the flow
 * @param flow - The flow that was changed
 * @param revert - A boolean that indicates if the changes should be reverted
 * @returns An object with the message, sectionId and stepId
 *
 */
export const messageSetter = (changes: Change[], flow: FlowIndexResponse | undefined, revert: boolean) => {
  let new_message = "";
  let info = {};

  changes.forEach((change: Change) => {
    const newValue = revert ? change.from : change.to;

    const messageGetter = getTypeFunction(change);

    new_message = messageGetter(change, newValue, flow) || "";
    const locale = localeGetter(change);

    if (!new_message) {
      return;
    }

    if (change.sectionId && change.stepId && flow) {
      info = {
        sectionId: change.sectionId,
        stepId: change.stepId,
        message: new_message,
        locale: locale,
      };
    } else if (change.sectionId && flow) {
      info = {
        sectionId: change.sectionId,
        message: new_message,
        locale: locale,
      };
    } else {
      info = {
        message: new_message,
        locale: locale,
      };
    }
  });
  return info;
};
