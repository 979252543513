const WarmspaceTheme = {
  appWidth: "970px",
  outerPadding: "0px 20px",
  global: {
    font: {
      family: "Avenir Next",
    },

    colors: {
      brand: "#ff622e",
      linearBrand: "linear-gradient(90deg, #ff622e 27.21%, #ff8d24 122.48%)",
    },
  },

  tip: {
    content: {
      background: "#444",
      color: "#222",
      elevation: "medium",
      size: "12px",
      margin: "xsmall",
      pad: { vertical: "xsmall", horizontal: "medium" },
      round: "small",
    },
  },

  button: {
    color: "#fff",

    size: {
      small: {
        border: {
          radius: "5px",
        },
      },
    },
  },
};

export default WarmspaceTheme;
