import React, { FC } from "react";
import styled from "styled-components";
import { KeyedMutator } from "swr";

import {
  isContentStep,
  isChoicesStep,
  isGoToStep,
  isBranchStep,
  isIframeStep,
  isMarkStepComplete,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
} from "~/lib/type_guards";
import ContentStepDetails from "./ContentStepDetails";
import AutomationStepDetails from "./AutomationStepDetails";
import ChoicesStepDetails from "./ChoicesStepDetails";
import GoToStepDetails from "./GoToStepDetails";
import BranchStepDetails from "./BranchStepDetails";
import ChoiceDetails from "./ChoiceDetails";
import ActionDetails from "./ActionDetails";
import SectionDetails from "./SectionDetails";
import IframeStepDetails from "./IframeStepDetails";
import MarkStepCompleteDetails from "./MarkStepCompleteDetails";
import FeedbackScoreDetails from "./FeedbackScoreStepDetails";

const Wrapper = styled.div`
  flex-shrink: 0;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DetailsPane = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 14px;
  height: 100%;
  padding: 18px;
  text-align: left;

  overflow-y: scroll;
`;

interface DetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection | undefined;
  step: AnyStep | undefined;
  viewMode: Roles;
  selectedChoice: number;
  selectedAction: number;
  locale: string;
}

const Details: FC<DetailsProps> = ({
  flow,
  section,
  step,
  mutate,
  viewMode,
  selectedChoice,
  selectedAction,
  locale,
}) => {
  return (
    <Wrapper>
      <small>{selectedChoice > -1 ? "Choice Details" : "Step Details"}</small>
      <DetailsPane>
        {!step && section && <SectionDetails mutate={mutate} flow={flow} section={section} />}
        {selectedAction > -1 ? (
          <>
            {isContentStep(step) && section && flow && (
              <ActionDetails
                actionIndex={selectedAction}
                mutate={mutate}
                flow={flow}
                section={section}
                step={step}
                viewMode={viewMode}
                locale={locale}
              />
            )}
          </>
        ) : selectedChoice > -1 ? (
          <>
            {isChoicesStep(step) && section && flow && (
              <ChoiceDetails
                choiceIndex={selectedChoice}
                mutate={mutate}
                flow={flow}
                section={section}
                step={step}
                viewMode={viewMode}
                locale={locale}
              />
            )}
          </>
        ) : (
          <>
            {isContentStep(step) && section && flow && (
              <ContentStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
            )}
            {isChoicesStep(step) && section && flow && (
              <>
                <ContentStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
                <ChoicesStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
              </>
            )}
            {isBranchStep(step) && section && flow && (
              <BranchStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
            )}
            {isGoToStep(step) && section && flow && (
              <GoToStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
            )}
            {isIframeStep(step) && section && flow && (
              <IframeStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
            )}
            {isMarkStepComplete(step) && section && flow && (
              <MarkStepCompleteDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
            )}
            {isFeedbackScoreStep(step) && section && flow && (
              <>
                <ContentStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
                <FeedbackScoreDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
              </>
            )}
            {isFeedbackWordsStep(step) && section && flow && (
              <ContentStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
            )}

            {!isContentStep(step) &&
              !isChoicesStep(step) &&
              !isGoToStep(step) &&
              !isBranchStep(step) &&
              !isIframeStep(step) &&
              !isMarkStepComplete(step) &&
              !isFeedbackScoreStep(step) &&
              !isFeedbackWordsStep(step) &&
              step &&
              section &&
              flow && (
                <AutomationStepDetails mutate={mutate} flow={flow} section={section} step={step} viewMode={viewMode} />
              )}
          </>
        )}
      </DetailsPane>
    </Wrapper>
  );
};

export default Details;
