import React, { FC } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import { Button } from "~/components/UI";
import styled from "styled-components";
import { KeyedMutator } from "swr";

import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
}

const CodeWrapper = styled.div`
  font-size: 14px;
  overflow-y: scroll;
`;

const JSONEdit: FC<Props> = ({ visible, setVisible, flow }) => {
  const [code] = React.useState(JSON.stringify(flow, null, 2));

  if (!visible) return null;
  return (
    <Layer
      onEsc={() => setVisible(false)}
      onClickOutside={() => setVisible(false)}
      animation="fadeIn"
      style={{ maxHeight: "95%", minWidth: "90%" }}
    >
      <Card background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            View flow as JSON
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "small" }}>
          <CodeWrapper>
            <CodeMirror value={code} theme="dark" extensions={[json()]} />
          </CodeWrapper>
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Close" />
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default JSONEdit;
