import React, { FC } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import { Button } from "~/components/UI";
import deleteSection from "~/lib/requests/deleteSection";
import { KeyedMutator } from "swr";
import { useNavigate } from "react-router-dom";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  section: FlowSection;
  flow: FlowIndexResponse;
  deleteSection: typeof deleteSection;
  mutate: KeyedMutator<FlowIndexResponse>;
  locale: string;
}

const DeleteSection: FC<Props> = ({ visible, setVisible, flow, section, deleteSection, mutate, locale }) => {
  const navigate = useNavigate();

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            Delete &quot;{section.title[locale]}&quot;
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "small" }}>
          <Text size="small">Are you sure you want to delete this section?</Text>
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

          <Button
            primary
            size="small"
            label="Delete Section"
            onClick={() => {
              deleteSection(flow.id, section.id, mutate);
              navigate("/session-flow/" + flow.id);
              setVisible(false);
            }}
          />
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default DeleteSection;
