import React, { FC, useState } from "react";
import { Box, TextInput } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";
import { Info } from "grommet-icons";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface IframeStepDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: IframeStep;

  viewMode: Roles;
}

const IframeStepDetails: FC<IframeStepDetailsProps> = ({ mutate, flow, step, section }) => {
  const [url, setUrl] = useState(step.settings.url || "");

  return (
    <>
      <Label>Url:</Label>
      <Box direction="row" align="center">
        <TextInput
          value={url || ""}
          onChange={(e) => {
            setUrl(e.target.value);
            //@ts-ignore
            updateStep(mutate, flow.id, section.id, step.id, {
              settings: {
                url: e.target.value,
              },
            });
          }}
          onBlur={(e) => {
            if (e.target.value) {
              //@ts-ignore
              updateStep(mutate, flow.id, section.id, step.id, {
                settings: {
                  url: e.target.value,
                },
              });
            }
          }}
        />
      </Box>
      <small>
        <Info size="small" /> It is possible to use the following variables in the url:
        <ul>
          <li>&#123;user_id&#125; - the id of the user</li>
          <li>&#123;invitation_id&#125; - the hashed id of the invitation</li>
        </ul>
        As the example below: http://url.com/&#123;user_id&#125;/&#123;invitation_id&#125;
      </small>
    </>
  );
};

export default IframeStepDetails;
