import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default (
  flowID: number,
  speakerBackground: File | undefined,
  listenerBackground: File | undefined,
  mutate: KeyedMutator<FlowIndexResponse>
) => {
  const formData = new FormData();

  if (speakerBackground) {
    formData.append("speakerBackground", speakerBackground || "");
  }
  if (listenerBackground) {
    formData.append("listenerBackground", listenerBackground || "");
  }

  return fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/background`, {
    method: "PUT",
    credentials: "include",
    body: formData,
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => {
      return mutate();
    })
    .catch(() => {
      toast(
        <>
          <b>Unable to upload background images</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
        </>
      );
    });
};
