import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default (mutate: KeyedMutator<FlowIndexResponse>, flowID: number, sectionID: number, title: LocaleText) => {
  fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/sections/${sectionID}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ title }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => mutate())
    .catch(() => {
      toast(
        <>
          <b>Unable to rename section</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
        </>
      );
    });
};
