import React, { FC, useEffect, useState } from "react";
import { TextInput, Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import {
  Chat,
  Waypoint,
  Directions,
  Cycle,
  Code,
  Risk,
  Apps,
  CheckboxSelected,
  BarChart,
  Contact,
} from "grommet-icons";
import { Button } from "~/components/UI";
import styled from "styled-components";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  addStep: (sectionID: number, title: LocaleText, type: string) => void;
  sectionID: false | number;
  locale: string;
}

const Form = styled.form`
  font-size: 14px;
`;

const StepTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StepTypeSelector = styled.button`
  margin-right: 10px;
  margin-bottom: 10px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  flex-shrink: 0;
  border: 0px;
  border-radius: 8px;
  background-color: #eee;

  display: flex;

  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;

  &:hover {
    background-color: #ddd;
  }
`;

type StepTypeStrings =
  | "ContentStep"
  | "ChoicesStep"
  | "TakeTurnsStep"
  | "RoleRotationStep"
  | "BranchStep"
  | "GoToStep"
  | "RoleRandomiserStep"
  | "IframeStep"
  | "MarkStepComplete"
  | "FeedbackScoreStep"
  | "FeedbackWordsStep";

interface StepType {
  name: string;
  type: StepTypeStrings;
  icon: React.ReactNode;
  suggestedName: string | undefined;
}

const PromptStepTypes: StepType[] = [
  { name: "Standard", type: "ContentStep", icon: <Chat />, suggestedName: undefined },
  { name: "Choices", type: "ChoicesStep", icon: <Apps />, suggestedName: undefined },
  { name: "Feedback Score", type: "FeedbackScoreStep", icon: <BarChart />, suggestedName: "Feedback Score" },
  { name: "Feedback Words", type: "FeedbackWordsStep", icon: <Contact />, suggestedName: "Feedback Words" },
];
const AutomationStepTypes: StepType[] = [
  { name: "Cycle roles", type: "RoleRotationStep", icon: <Cycle />, suggestedName: "Cycle roles" },
  { name: "Randomise roles", type: "RoleRandomiserStep", icon: <Risk />, suggestedName: "Randomise roles" },
  { name: "Branch", type: "BranchStep", icon: <Directions />, suggestedName: "Branch" },
  { name: "Go to step", type: "GoToStep", icon: <Waypoint />, suggestedName: "Go to" },
  { name: "Iframe step", type: "IframeStep", icon: <Code />, suggestedName: "Iframe step" },
  {
    name: "Mark step complete",
    type: "MarkStepComplete",
    icon: <CheckboxSelected />,
    suggestedName: "Mark step complete for role A user",
  },
];

const NewStep: FC<Props> = ({ visible, setVisible, addStep, sectionID, locale }) => {
  const [textValue, setTextValue] = useState("");
  const [stepType, setStepType] = useState<StepTypeStrings | undefined>(undefined);

  useEffect(() => {
    setStepType(undefined);
  }, [visible]);

  if (!visible || !sectionID) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            Add a Step
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "small" }}>
          {!stepType && (
            <Form>
              <label>Prompts / Interactive</label>
              <StepTypes>
                {PromptStepTypes.map((s) => {
                  return (
                    <StepTypeSelector
                      key={s.name}
                      onClick={(e) => {
                        e.preventDefault();
                        setStepType(s.type);
                      }}
                      type="button"
                    >
                      <div></div>
                      {s.icon}
                      {s.name}
                    </StepTypeSelector>
                  );
                })}
              </StepTypes>
              <label>Automation</label>

              <StepTypes>
                {AutomationStepTypes.map((s) => {
                  return (
                    <StepTypeSelector
                      key={s.name}
                      onClick={(e) => {
                        e.preventDefault();
                        setStepType(s.type);
                        s.suggestedName && setTextValue(s.suggestedName);
                      }}
                    >
                      <div></div>
                      {s.icon}
                      {s.name}
                    </StepTypeSelector>
                  );
                })}
              </StepTypes>
            </Form>
          )}

          {stepType && (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                setVisible(false);
                addStep(
                  sectionID,
                  {
                    [locale]: textValue,
                  },
                  stepType
                );
                setTextValue("");
              }}
            >
              <label>Step Title:</label>
              <TextInput
                placeholder="Introduction"
                value={textValue}
                onChange={(event) => setTextValue(event.target.value)}
                style={{ marginBottom: "30px" }}
                autoFocus
              />
            </Form>
          )}
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

          {stepType && (
            <Button
              primary
              size="small"
              label="Add Step"
              onClick={() => {
                addStep(
                  sectionID,
                  {
                    [locale]: textValue,
                  },
                  stepType
                );
                setTextValue("");
                setVisible(false);
              }}
            />
          )}
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default NewStep;
