import React, { FC, useEffect, useRef, useState } from "react";
import { Clock, Clone } from "grommet-icons";
import { Select, Text, Box, TextInput } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";

import updateStep from "~/lib/requests/updateStep";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

const shareModeOptions = [
  { value: true, label: "Share content", selectValue: "true" },
  { value: false, label: "Different content", selectValue: "false" },
];

const clearDoneOptions = [
  { value: false, label: "Remember", selectValue: "false" },
  { value: true, label: "Forget", selectValue: "true" },
];

interface ContentStepDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: ContentStep | ChoicesStep | FeedbackScoreStep | FeedbackWordsStep;

  viewMode: Roles;
}

const ContentStepDetails: FC<ContentStepDetailsProps> = ({ flow, section, step, mutate, viewMode }) => {
  const actionTimingInput = useRef<HTMLInputElement>(null);
  const buttonHideDurationInput = useRef<HTMLInputElement>(null);

  const [buttonHideDuration, setButtonHideDuration] = useState<string>("");
  const [actionTiming, setActionTiming] = useState<string>("");

  useEffect(() => {
    const actionTiming = (step.content[viewMode].actionTiming && step.content[viewMode].actionTiming.toString()) || "0";

    setActionTiming(actionTiming);
  }, [step.content[viewMode].actionTiming]);

  useEffect(() => {
    const buttonHideDuration =
      step.settings.buttonHideDuration === 0
        ? "0"
        : (step.settings.buttonHideDuration && step.settings.buttonHideDuration.toString()) || "6";

    setButtonHideDuration(buttonHideDuration);
  }, [step]);

  const updateActionTiming = (timing: string) => {
    if (timing === "") {
      timing = "0";
    }

    if (flow && section && step) {
      const newContent: BasicStepContent = Object.assign({}, step.content[viewMode]);
      newContent.actionTiming = parseInt(timing);

      // @ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  const updateButtonHideDuration = (duration: string) => {
    if (duration === "") {
      setButtonHideDuration("6");
    }

    if (flow && section && step) {
      const newDuration = parseInt(duration);

      // @ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { settings: { buttonHideDuration: newDuration } });
    }
  };

  return (
    <>
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <Clone />
          Share content?
        </Label>
      </Text>
      <Select
        options={shareModeOptions}
        value={step.shareContent ? "true" : "false"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          // @ts-ignore
          updateStep(mutate, flow.id, section.id, step.id, { shareContent: option.value });
        }}
        a11yTitle="A drop down to select whether or not the content for the step should be shared by all roles"
      />
      <br />
      {step.shareContent ? "Everyone will see the same content." : "Roles can have different content."}
      <br />
      <br />
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <Clone />
          Remember completion?
        </Label>
      </Text>
      <Select
        options={clearDoneOptions}
        value={step.settings.clearDone ? "true" : "false"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          // @ts-ignore
          updateStep(mutate, flow.id, section.id, step.id, { settings: { clearDone: option.value } });
        }}
        a11yTitle="A drop down to select whether or not the content for the step should be shared by all roles"
      />
      <br />
      {step.settings.clearDone
        ? "Participants can complete this step multiple times."
        : "Participants can complete this step only once."}
      <br />
      <br />
      <h3>Timing</h3>
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <Clock />
          Duration (seconds):
        </Label>
      </Text>
      <Box direction="row" align="center" margin={{ bottom: "small" }}>
        <TextInput
          suggestions={["0", "60", "120", "180", "300", "600"]}
          onSuggestionSelect={(e) => {
            setActionTiming(e.suggestion);
            actionTimingInput.current?.blur();
          }}
          type="number"
          style={{ maxWidth: "100px", width: "100px" }}
          ref={actionTimingInput}
          value={actionTiming}
          onChange={(e) => {
            setActionTiming(e.target.value);
          }}
          onBlur={(e) => {
            updateActionTiming(e.target.value);
          }}
        />
      </Box>
      The chime will sound and button will flash after this time if configured in the Flow Settings.
      <br />
      <h3>Button visibility</h3>
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <Clock />
          Show buttons after:
        </Label>
      </Text>
      <Box direction="row" align="center" justify="start" margin={{ bottom: "small" }}>
        <Box margin={{ right: "small" }}>
          <TextInput
            suggestions={["0", "6"]}
            onSuggestionSelect={(e) => {
              setButtonHideDuration(e.suggestion);
              buttonHideDurationInput.current?.blur();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateButtonHideDuration(e.currentTarget.value);
              }
            }}
            type="number"
            style={{ maxWidth: "100px", width: "100px" }}
            ref={buttonHideDurationInput}
            value={buttonHideDuration}
            onChange={(e) => {
              setButtonHideDuration(e.target.value);
            }}
            onBlur={(e) => {
              updateButtonHideDuration(e.target.value);
            }}
          />
        </Box>
        seconds
      </Box>
      Buttons start off hidden and will be revealed after this amount of seconds has passed. The default is 6 seconds.
    </>
  );
};

export default ContentStepDetails;
