import React, { useState, FC } from "react";
import { FormAdd, Trash } from "grommet-icons";
import purgeAudio from "~/lib/requests/deleteAudio";
import { KeyedMutator } from "swr";
import uploadFile from "~/lib/requests/uploadFile";
import { AddAudioButton } from "~/components/UI/AddButton";

import { Audio, AudioFilename, DeleteAudio } from "./StepCanvas.styles";

interface MediaUploaderProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  section: FlowSection;
  step: ContentStep;
  viewMode: Roles;
  locale: string;
}

const hasLocaleAudioFile = (step: ContentStep, locale: string) => {
  return step.audioFile && step.audioFile[locale];
};

const MediaUploader: FC<MediaUploaderProps> = ({ flow, mutate, section, step, locale }) => {
  const [uploading, setUploading] = useState(false);

  const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (flow && section && step) {
      setUploading(true);
      await uploadFile(mutate, flow.id, section.id, step.id, event, locale);
      setUploading(false);
    }
  };
  return (
    <>
      {!hasLocaleAudioFile(step, locale) && (
        <AddAudioButton tabIndex={1} htmlFor="fileupload" role="button">
          <FormAdd />
          {uploading ? "Uploading .. " : "Add Audio/Video"}
          {!uploading && (
            <input id="fileupload" type="file" style={{ display: "none" }} onChange={onUploadFile} multiple={true} />
          )}
        </AddAudioButton>
      )}

      {hasLocaleAudioFile(step, locale) && (
        <Audio>
          <AudioFilename>
            Media uploaded: <a href={step.audioFile[locale]?.url}>{step.audioFile[locale]?.fileName}</a>
          </AudioFilename>
          <audio
            key={step.audioFile[locale]?.url}
            //@ts-ignore
            src={step.audioFile[locale]?.url}
            controls
          />
          <DeleteAudio
            data-testid="delete-audio"
            onClick={() => {
              purgeAudio(mutate, flow.id, section.id, step.id, locale);
            }}
          >
            <Trash />
          </DeleteAudio>
        </Audio>
      )}
    </>
  );
};

export default MediaUploader;
