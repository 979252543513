import React from "react";
import { KeyedMutator } from "swr";
import updateFlow from "~/lib/requests/updateFlow";
import updateSection from "~/lib/requests/updateSection";
import updateSectionTitle from "~/lib/requests/updateSectionTitle";
import updateStep from "~/lib/requests/updateStep";
import updateStepTitle from "~/lib/requests/updateStepTitle";
import { messageSetter } from "../messageSetter";

const invertChanges = (changes: Change[]) => {
  return changes.map((change: Change) => {
    return {
      ...change,
      from: change.to,
      to: change.from,
    };
  });
};

/**
 * A function that takes the changes made through undo/redo and maps them to the correct request
 * It also sets the message that will be displayed in the toast
 * @param changes - An array of objects containing the path to the changed value, the old value and the new value
 * @param revert - A boolean that indicates if the changes should be reverted or not
 * @param mutate - The mutate function from SWR
 * @param initialValue - The initial value of the flow
 * @param expectedChanges - An array of changes that do not come from undo/redo
 * @param setExpectedChanges - A function that sets the expected changes
 * @returns A message that will be displayed in the toast
 */
export const requestMapper = (
  changes: Change[],
  revert: boolean,
  mutate: KeyedMutator<FlowIndexResponse>,
  initialValue: FlowIndexResponse | undefined,
  expectedChanges: Change[][],
  setExpectedChanges: React.Dispatch<React.SetStateAction<Change[][]>>
) => {
  const updateMapper = (
    isStep: boolean,
    isSection: boolean,
    sectionId: string,
    stepId: string | undefined,
    new_value: any,
    title: boolean
  ) => {
    if (initialValue) {
      const updateFunction = isStep ? updateStep : updateSection;
      const updateTitleFunction = isStep && title ? updateStepTitle : updateSectionTitle;

      if (isStep && title) {
        // @ts-ignore
        updateTitleFunction(mutate, initialValue.id, sectionId, stepId, new_value);
      } else if (isStep && !title) {
        // @ts-ignore
        updateFunction(mutate, initialValue.id, sectionId, stepId, new_value);
      } else if (isSection && title) {
        // @ts-ignore
        updateTitleFunction(mutate, initialValue.id, sectionId, new_value);
      } else if (isSection && !title) {
        // @ts-ignore
        updateFunction(mutate, initialValue, sectionId, new_value);
      }
    }
  };

  changes.forEach((change: Change) => {
    const new_value = revert ? change.from : change.to;

    if (initialValue && change.type === "section") {
      const sectionId = change.sectionId;

      if (change.path[change.path.length - 2] === "title") {
        // @ts-ignore
        updateMapper(false, true, sectionId, undefined, new_value.title, true);
      } else {
        // @ts-ignore
        updateMapper(false, true, sectionId, undefined, new_value, false);
      }
    } else if (initialValue && change.type === "step") {
      const sectionId = change.sectionId;
      const stepId = change.stepId;

      if (change.path[change.path.length - 2] === "title") {
        // @ts-ignore
        updateMapper(true, false, sectionId, stepId, new_value.title, true);
      } else {
        // @ts-ignore
        updateMapper(true, false, sectionId, stepId, new_value, false);
      }
    } else if (initialValue) {
      updateFlow(initialValue, new_value, mutate);
    }
  });

  const expectedChange = revert ? invertChanges(changes) : changes;
  setExpectedChanges([...expectedChanges, expectedChange]);

  return messageSetter(changes, initialValue, revert);
};
