import React, { FC, useState } from "react";
import { TextInput, Layer, Card, CardBody, CardFooter, Tabs, Tab, Select, Spinner } from "grommet";
import { Button } from "~/components/UI";
import styled from "styled-components";
import useSWR, { KeyedMutator } from "swr";
import { fetcher } from "~/lib/fetcher";
import duplicateSection from "~/lib/requests/duplicateSection";
import { Alert, FormDown } from "grommet-icons";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  addSection: (name: { title: LocaleText }) => void;
  locale: string;
  currentFlow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
}

const Form = styled.form`
  font-size: 14px;
`;

const StyledTabs = styled(Tabs)`
  &.modal-tabs span {
    font-size: 14px;
    line-height: 1;
  }
`;

const NewSection: FC<Props> = ({ visible, setVisible, addSection, locale, currentFlow, mutate }) => {
  const [textValue, setTextValue] = useState({ title: { [locale]: "" } });
  const [flowFilter, setFlowFilter] = useState("");
  const [selectedFlow, setSelectedFlow] = useState("");
  const [selectedSection, setSelectedSection] = useState<FlowSection | undefined>(undefined);
  const [sectionOptions, setSectionOptions] = useState<{ label: string; value: FlowSection }[] | undefined>(undefined);

  const { data: flows, error } = useSWR<FlowIndexResponse[]>(`${process.env.FLOW_BUILDER_API_URL}/flows`, fetcher, {
    refreshInterval: 0,
    revalidateOnFocus: true,
  });
  const flowOptions =
    flows
      ?.filter((flow) => flow.id !== currentFlow.id && flow.name.toLowerCase().includes(flowFilter.toLowerCase()))
      .map((flow) => ({ label: flow.name, value: flow.id, sections: flow.sections })) || [];

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <StyledTabs
          className="modal-tabs"
          style={{
            paddingTop: "15px",
          }}
        >
          <Tab title="Add a New Section">
            <CardBody pad={{ horizontal: "small", bottom: "30px", top: "small" }}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setVisible(false);
                  addSection(textValue);
                  setTextValue({ title: { [locale]: "" } });
                }}
              >
                <label>Section Title:</label>
                <TextInput
                  placeholder="Introduction"
                  value={textValue.title[locale] || ""}
                  onChange={(event) =>
                    setTextValue({
                      title: {
                        [locale]: event.target.value,
                      },
                    })
                  }
                  autoFocus
                />
              </Form>
            </CardBody>
            <CardFooter pad="small" background="light-2">
              <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

              <Button
                primary
                size="small"
                label="Add Section"
                onClick={() => {
                  addSection(textValue);
                  setTextValue({ title: { [locale]: "" } });
                  setVisible(false);
                }}
              />
            </CardFooter>
          </Tab>
          <Tab title="Copy from Another Flow">
            <CardBody pad={{ horizontal: "small", bottom: "30px", top: "small" }}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setVisible(false);
                  duplicateSection(mutate, currentFlow.id, selectedSection?.id || 0);
                }}
              >
                <>
                  <label>Select a Flow:</label>
                  <br />
                  <Select
                    id="flow-select"
                    options={flowOptions || []}
                    value={selectedFlow}
                    labelKey="label"
                    onSearch={(value) => setFlowFilter(value)}
                    onChange={({ option }) => {
                      setSelectedFlow(option.label);
                      setSectionOptions(
                        option.sections.map((section: FlowSection) => ({
                          label: section.title[locale],
                          value: section,
                        }))
                      );
                      setFlowFilter("");
                    }}
                    dropAlign={{ top: "top", left: "left" }}
                    dropHeight="small"
                    emptySearchMessage="No flows found."
                    disabled={!flows}
                    icon={flows && !error ? <FormDown color="#ffa100" /> : error ? <Alert /> : <Spinner />}
                  />
                  <br />
                  {error ? (
                    <>
                      <small>There was an error loading the flows.</small>
                    </>
                  ) : null}

                  {selectedFlow && (
                    <>
                      <br />
                      <br />
                      <label>Select a Section:</label>
                      <br />
                      <Select
                        options={sectionOptions || []}
                        value={selectedSection?.title[locale] || ""}
                        labelKey="label"
                        onChange={({ option }) => {
                          setSelectedSection(option.value);
                        }}
                        data-testid="section-select"
                        emptySearchMessage="No sections found."
                      />
                    </>
                  )}
                </>
              </Form>
            </CardBody>
            <CardFooter pad="small" background="light-2">
              <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

              <Button
                primary
                size="small"
                label="Copy Section"
                disabled={!selectedSection}
                onClick={() => {
                  duplicateSection(mutate, currentFlow.id, selectedSection?.id || 0);
                  setVisible(false);
                }}
              />
            </CardFooter>
          </Tab>
        </StyledTabs>
      </Card>
    </Layer>
  );
};

export default NewSection;
