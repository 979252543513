import React, { FC, useState, useEffect } from "react";

import { Liquid } from "liquidjs";
import shuffle from "~/lib/shuffle";

const liquidEngine = new Liquid({ strictFilters: true, strictVariables: true });

liquidEngine.registerFilter("shuffle", (a, seed = 1) => shuffle(a, seed));

interface LiquidRenderProps {
  template: string;
  data: {
    [key in string]: {
      [key: string]: string;
    };
  };
}

const LiquidRender: FC<LiquidRenderProps> = ({ template, data }) => {
  const [result, setResult] = useState("");

  useEffect(() => {
    liquidEngine
      .parseAndRender(template, { data })
      .then(setResult)
      .catch((e) => {
        setResult(e.toString());
      });
  });

  return <>{result || "..."}</>;
};

export default LiquidRender;
