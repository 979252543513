import React from "react";
import { toast } from "react-toastify";

export interface TranslationRequest {
  [key: string]: TranslationRequestString;
}

interface TranslationRequestString {
  sourceText: string;
  targetLanguages: string[];
}

export interface TranslationResponse {
  [key: string]: TranslationResponseString;
}

interface TranslationResponseString {
  [languageCode: string]: string;
}

export default (flowID: number, request: TranslationRequest): Promise<TranslationResponse> => {
  return fetch(`${process.env.FLOW_BUILDER_API_URL}/translate`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then((json) => {
      return JSON.parse(json.choices[0].message.content);
    })
    .catch((err) => {
      console.error(err);
      toast(
        <>
          <b>Unable to translate flow</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
          <br />
          <i>{err.message}</i>
        </>
      );
    });
};
