import React, { FC, useState, useRef } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text, TextArea } from "grommet";
import { Button } from "~/components/UI";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import { textForLocale } from "~/lib/textForLocale";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  mutate: KeyedMutator<FlowIndexResponse>;
  updateStep: (
    mutate: KeyedMutator<FlowIndexResponse>,
    flowID: number,
    sectionID: number,
    stepID: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => void;
  sectionID: number;
  stepID: number;
  flowID: number;
  initialChoices: Choice[];
  viewMode: Roles;
  locale: string;
  flow: FlowIndexResponse;
}

const StyledTextArea = styled(TextArea)`
  height: 300px;
  font-size: 16px;
`;

const AddChoices: FC<Props> = ({
  mutate,
  visible,
  setVisible,
  updateStep,
  flowID,
  stepID,
  sectionID,
  initialChoices,
  viewMode,
  locale,
  flow,
}) => {
  const textArea = useRef<HTMLTextAreaElement>(null);
  const [choices, setChoices] = useState(initialChoices.map((c) => textForLocale(c.text, locale)).join("\n"));

  const saveChoices = () => {
    setVisible(false);

    const newChoices = textArea.current?.value
      .split("\n")
      .filter((x) => x.trim() !== "")
      .map((x) => {
        return x.trim();
      });

    const stepContent = flow.sections.find((s) => s.id === sectionID)?.steps.find((s) => s.id === stepID);
    //@ts-ignore
    let choices = stepContent?.content?.[viewMode].choices || [];

    if (newChoices) {
      if (choices.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        choices = choices.map((c: any, i: number) => {
          c.text[locale] = newChoices[i];
          return c;
        });

        if (newChoices.length > choices.length) {
          choices.push(
            ...newChoices.slice(choices.length).map((c) => {
              return {
                text: {
                  [locale]: c,
                },
              };
            })
          );
        }
      } else {
        choices.push(
          ...newChoices.map((c) => {
            return {
              text: {
                [locale]: c,
              },
            };
          })
        );
      }
    }

    // if the text property is undefined, remove the object
    choices = choices.filter((c: { text: { [x: string]: string } }) => {
      return c.text[locale] !== undefined;
    });
    const value = {
      content: {
        [viewMode]: {
          choices: choices,
        },
      },
    };

    updateStep(mutate, flowID, sectionID, stepID, value);
  };

  if (!visible || !sectionID || !stepID) return null;

  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            Edit choices
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "small" }}>
          <small>Separate choices with an Enter.</small>
          <StyledTextArea
            ref={textArea}
            value={choices}
            onChange={(e) => {
              setChoices(e.target.value);
            }}
            id="choices-textarea"
          />
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

          <Button primary size="small" label="Save" onClick={saveChoices} />
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default AddChoices;
