import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default (
  mutate: KeyedMutator<FlowIndexResponse>,
  flowID: number,
  sectionID: number,
  stepID: number,
  event: React.ChangeEvent<HTMLInputElement>,
  locale: string
) => {
  const files = event.target.files;
  const formData = new FormData();

  if (!files) return;
  if (!files[0]) return;

  formData.append("audio", files[0]);

  return fetch(
    `${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/sections/${sectionID}/steps/${stepID}/audio/${locale}`,
    {
      method: "PUT",
      credentials: "include",
      body: formData,
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => {
      return mutate();
    })
    .catch(() => {
      toast(
        <>
          <b>Unable to upload file</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
        </>
      );
    });
};
