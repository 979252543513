import React, { FC, useState } from "react";
import { KeyedMutator } from "swr";
import styled from "styled-components";

import AddChoices from "~/components/FlowBuilder/Modals/AddChoices";
import updateStep from "~/lib/requests/updateStep";
import AddButton from "~/components/UI/AddButton";

import PromptEditor from "./PromptEditor";
import { BottomArea, PreviewInner, UpperArea, CenterArea } from "./StepCanvas.styles";
import { ActionButton, ActionButtonStack } from "./StepCanvas.styles";
import { textForLocale } from "~/lib/textForLocale";

interface ChoicesListProps {
  width: number;
  columns: number;
}

export const ChoicesList = styled.div<ChoicesListProps>`
  width: ${({ width }: { width: number }) => width}%;
  display: grid;
  grid-template-columns: ${({ columns }: { columns: number }) =>
    Array(columns + 1)
      .fill("")
      .join("1fr ")};

  grid-gap: 10px;

  ${ActionButton} {
    width: 100%;
    margin-right: 0px;
  }

  ${ActionButtonStack} {
    margin-right: 0px;
  }
`;

interface ChoiceStepProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;

  section: FlowSection;
  step: ChoicesStep;

  viewMode: Roles;

  selectedChoice: number;
  setSelectedChoice: React.Dispatch<React.SetStateAction<number>>;
  locale: string;
}

const ChoiceStep: FC<ChoiceStepProps> = ({
  flow,
  mutate,
  section,
  step,
  viewMode,
  selectedChoice,
  setSelectedChoice,
  locale,
}) => {
  const [showChoicesModal, setShowChoicesModal] = useState(false);

  return (
    <PreviewInner>
      {showChoicesModal && (
        <AddChoices
          setVisible={setShowChoicesModal}
          mutate={mutate}
          visible={showChoicesModal}
          stepID={step.id}
          flowID={flow.id}
          sectionID={section.id}
          updateStep={updateStep}
          viewMode={viewMode}
          initialChoices={
            step.content[viewMode].choices?.map((c) => {
              return {
                text: {
                  [locale]: textForLocale(c.text, locale),
                },
                image: c.image,
                onClick: c.onClick,
              };
            }) || []
          }
          locale={locale}
          flow={flow}
        />
      )}
      <UpperArea>
        <PromptEditor viewMode={viewMode} mutate={mutate} flow={flow} section={section} step={step} locale={locale} />
      </UpperArea>
      <CenterArea>
        <AddButton
          onClick={() => setShowChoicesModal(true)}
          style={{ marginTop: "-40px", marginBottom: "10px", width: "auto", padding: "10px" }}
        >
          Edit Choices
        </AddButton>

        {
          <ChoicesList
            width={step.content[viewMode].choiceListWidth || 90}
            columns={step.content[viewMode].columns || 3}
          >
            {step.content[viewMode].choices?.map((choice: Choice, i: number) => {
              // Remove any choices that have no text
              if (!textForLocale(choice.text, locale)) return null;
              return (
                <ActionButtonStack anchor="top-right" key={"button" + i}>
                  <ActionButton
                    primary
                    label={textForLocale(choice.text, locale)}
                    className={selectedChoice === i ? "selected" : ""}
                    size="small"
                    style={{ height: step.content[viewMode].buttonHeight || "auto " }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedChoice(i);
                    }}
                    id={"choice" + i}
                  />
                </ActionButtonStack>
              );
            })}
          </ChoicesList>
        }
      </CenterArea>
      <BottomArea></BottomArea>
    </PreviewInner>
  );
};

export default ChoiceStep;
