import React, { FC, useEffect } from "react";
import { Box } from "grommet";
import { Lock } from "grommet-icons";

import { ContentToggle, ContentToggleButton, ContentToggleInfo } from "./StepCanvas.styles";
import { EVERYONE_ELSE, ROLES, ROLE_A } from "~/lib/defaults";

interface ViewModeToggleProps {
  setViewMode: React.Dispatch<React.SetStateAction<Roles>>;
  viewMode: Roles;
  step: ContentStep | ChoicesStep | IframeStep | FeedbackScoreStep | FeedbackWordsStep;
}

const ViewModeToggle: FC<ViewModeToggleProps> = ({ viewMode, setViewMode, step }) => {
  const RoleToLabel = {
    A: "A",
    B: "B",
    EVERYONE_ELSE: "Everyone else",
  };

  const toggleButtonClasses = (buttonRole: Roles) => {
    const classes = [];

    if (buttonRole === viewMode) {
      classes.push("active");
    }

    if (!step.shareContent) {
      classes.push("enabled");
    }

    return classes;
  };

  useEffect(() => {
    if (!step.shareContent) {
      setViewMode(ROLE_A);
    } else {
      setViewMode(EVERYONE_ELSE);
    }
  }, [step.shareContent]);

  return (
    <Box direction="row">
      <ContentToggle>
        {ROLES.map((role) => {
          return (
            <ContentToggleButton
              key={role}
              className={toggleButtonClasses(role).join(" ")}
              onClick={setViewMode.bind(undefined, role)}
              disabled={step.shareContent}
            >
              {RoleToLabel[role]}
            </ContentToggleButton>
          );
        })}
      </ContentToggle>
      <ContentToggleInfo>
        {step.shareContent && (
          <>
            <Lock />
            Everyone is sharing the same content
          </>
        )}
      </ContentToggleInfo>
    </Box>
  );
};

export default ViewModeToggle;
