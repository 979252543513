import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Flags from "country-flag-icons/react/3x2";
import EditableTextArea from "../EditableTextArea";
import updateStep from "~/lib/requests/updateStep";
import { KeyedMutator } from "swr";
import updateSection from "~/lib/requests/updateSection";
import { missingLocaleMap } from "~/components/UI/LanguageSelector";
import { isContentStep, isFeedbackScoreStep, isFeedbackWordsStep } from "~/lib/type_guards";

interface OutlineProps {
  flow: FlowIndexResponse;
  languages: Language[] | undefined;
  mutate: KeyedMutator<FlowIndexResponse>;
}

const Wrapper = styled.div`
  overflow-y: scroll;
  width: 100%;
  table {
    width: 100%;
    font-size: 16px;

    border-radius: 10px;
    border: 1px solid #ccc;

    .firstColumn {
      min-width: 100px !important;
      width: 100px;
    }

    .languageHeader {
      text-transform: uppercase;
      font-size: 12px;
      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .sectionNumber,
    .stepNumber {
      text-align: right;
      small {
        color: #222;
      }
    }

    .section td {
      background-color: #ddd;
      font-weight: 500;
      border-bottom: 1px solid #888;
    }

    .step td {
      background-color: #eee;
      font-weight: 500;
    }

    .stepNumber {
      small {
        color: #333;
      }
    }

    td {
      background-color: #fff;
      padding: 6px;
      min-width: 250px;
      vertical-align: top;
    }

    .action {
      display: inline-block;
      padding: 4px 10px;
      background-color: #ccc;
      border-radius: 4px;
      color: #333;
      font-style: normal;

      &.untranslated {
        color: #999;
        font-style: italic;
      }
    }

    .untranslated > div {
      font-style: italic;
      color: #999;
    }
  }
`;

const stepRow = (
  role: Roles,
  mutate: KeyedMutator<FlowIndexResponse>,
  step: ContentStep | FeedbackWordsStep | FeedbackScoreStep,
  section: FlowSection,
  flow: FlowV1,
  stepCounter: number
) => {
  return (
    <>
      <tr key={`step-${role}-${step.id}`}>
        <td className="stepNumber firstColumn">
          <small>
            {stepCounter}.{role === "EVERYONE_ELSE" ? "Everyone" : role}
          </small>
        </td>
        {flow.translatedLanguages.map((language: string) => (
          <td key={`language-${language}`} className={step.content[role].text[language] ? "" : "untranslated"}>
            <EditableTextArea
              initiallyEditing={false}
              initialValue={step.content[role].text[language] || step.content[role].text["en"]}
              valueTransformer={(value: string) => ({
                content: {
                  [role]: {
                    text: {
                      ...step.content[role].text,
                      [language]: value,
                    },
                  },
                },
              })}
              onCommit={(value) => {
                updateStep(mutate, flow.id, section.id, step.id, value);
              }}
            />

            <div>
              {step.content[role].actions &&
                step.content[role].actions.map((action, actionIndex) => (
                  <div
                    className={action.buttonText[language] ? "action" : "action untranslated"}
                    key={`action-${actionIndex}`}
                  >
                    <EditableTextArea
                      initiallyEditing={false}
                      initialValue={action.buttonText[language] || action.buttonText["en"]}
                      valueTransformer={(value: string) => value}
                      onCommit={(value) => {
                        const newActions: Action[] = [...step.content[role].actions];
                        const newContent: BasicStepContent = Object.assign({}, step.content[role]);
                        newActions[actionIndex].buttonText[language] = value;
                        newContent.actions = newActions;

                        // @ts-ignore
                        updateStep(mutate, flow.id, section.id, step.id, { content: { [role]: newContent } });
                      }}
                    />
                  </div>
                ))}
            </div>
          </td>
        ))}
      </tr>
      {isFeedbackScoreStep(step) && (
        <>
          <tr key={`step-${role}-${step.id}-lowScoreLabel`}>
            <td className="stepNumber firstColumn">
              <small>
                {stepCounter}.{role === "EVERYONE_ELSE" ? "Everyone" : role}.LowLabel
              </small>
            </td>
            {flow.translatedLanguages.map((language: string) => (
              <td
                key={`language-${language}`}
                className={step.content[role]?.descriptionLow?.[language] ? "" : "untranslated"}
              >
                <EditableTextArea
                  initiallyEditing={false}
                  initialValue={
                    step.content[role]?.descriptionLow?.[language] || step.content[role]?.descriptionLow?.["en"]
                  }
                  valueTransformer={(value: string) => ({
                    content: {
                      [role]: {
                        descriptionLow: {
                          ...step.content[role].descriptionLow,
                          [language]: value,
                        },
                      },
                    },
                  })}
                  onCommit={(value) => {
                    updateStep(mutate, flow.id, section.id, step.id, value);
                  }}
                />
              </td>
            ))}
          </tr>
          <tr key={`step-${role}-${step.id}-highScoreLabel`}>
            <td className="stepNumber firstColumn">
              <small>
                {stepCounter}.{role === "EVERYONE_ELSE" ? "Everyone" : role}.HighLabel
              </small>
            </td>
            {flow.translatedLanguages.map((language: string) => (
              <td
                key={`language-${language}`}
                className={step.content[role]?.descriptionHigh?.[language] ? "" : "untranslated"}
              >
                <EditableTextArea
                  initiallyEditing={false}
                  initialValue={
                    step.content[role]?.descriptionHigh?.[language] || step.content[role]?.descriptionHigh?.["en"]
                  }
                  valueTransformer={(value: string) => ({
                    content: {
                      [role]: {
                        descriptionHigh: {
                          ...step.content[role].descriptionHigh,
                          [language]: value,
                        },
                      },
                    },
                  })}
                  onCommit={(value) => {
                    updateStep(mutate, flow.id, section.id, step.id, value);
                  }}
                />
              </td>
            ))}
          </tr>
        </>
      )}
    </>
  );
};

export const Outline: FC<OutlineProps> = ({ flow, languages, mutate }) => {
  let stepCounter = 0;

  const storeScrollPosition = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    sessionStorage.setItem("flowsScrollPosition", target.scrollTop.toString());
  };

  // Restore scroll position
  useEffect(() => {
    const target = document.getElementById("outline-table") as HTMLDivElement;
    target.scrollTop = Number(sessionStorage.getItem("flowsScrollPosition"));
  }, []);

  return (
    <Wrapper id={"outline-table"} onScroll={storeScrollPosition}>
      <table>
        <tbody>
          <tr key="firstRow">
            <td className="firstColumn"></td>
            {flow.translatedLanguages.map((language) => {
              const locale = languages?.find((lang) => lang.code === language)?.name;

              // @ts-ignore
              const Flag = Flags[missingLocaleMap[locale] || language.toUpperCase()];

              return (
                <td className="languageHeader" key={`language-${language}`}>
                  <div>
                    {Flag ? <Flag width={"20px"} height={"20px"} /> : ""}
                    <b>{languages?.find((lang) => lang.code === language)?.name}</b>
                  </div>
                </td>
              );
            })}
          </tr>
          {flow.sections.map((section, n) => {
            return (
              <React.Fragment key={`section-${section.id}`}>
                <tr className="section">
                  <td className="sectionNumber firstColumn"></td>
                  {flow.translatedLanguages.map((language) => (
                    <td
                      className={section.title[language] ? "" : "untranslated"}
                      key={`section-title-${language}-${section.id}`}
                    >
                      <EditableTextArea
                        initiallyEditing={false}
                        initialValue={section.title[language] || section.title["en"]}
                        valueTransformer={(value: string) => ({
                          title: {
                            ...section.title,
                            [language]: value,
                          },
                        })}
                        onCommit={(value) => {
                          updateSection(mutate, flow, section.id, value);
                        }}
                      />
                    </td>
                  ))}
                </tr>
                {section.steps.map((step, i) => {
                  stepCounter += 1;
                  return (
                    <React.Fragment key={`step-title-${step.id}`}>
                      <tr className="step">
                        <td className="stepNumber firstColumn">
                          <small>{stepCounter}.Title</small>
                        </td>
                        {flow.translatedLanguages.map((language) => (
                          <td
                            className={step.title[language] ? "" : "untranslated"}
                            key={`step-title-${language}-${step.id}`}
                          >
                            <EditableTextArea
                              initiallyEditing={false}
                              initialValue={step.title[language] || step.title["en"]}
                              valueTransformer={(value: string) => ({
                                title: {
                                  ...step.title,
                                  [language]: value,
                                },
                              })}
                              onCommit={(value) => {
                                updateStep(mutate, flow.id, section.id, step.id, value);
                              }}
                            />
                          </td>
                        ))}
                      </tr>
                      {(isContentStep(step) || isFeedbackScoreStep(step) || isFeedbackWordsStep(step)) && (
                        <>
                          {!step.shareContent && stepRow("A", mutate, step, section, flow, stepCounter)}
                          {!step.shareContent && stepRow("B", mutate, step, section, flow, stepCounter)}
                          {stepRow("EVERYONE_ELSE", mutate, step, section, flow, stepCounter)}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
                <tr key={`separator-${section.id}`}>
                  <td className="firstColumn" style={{ backgroundColor: "transparent" }}></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <br />
    </Wrapper>
  );
};
