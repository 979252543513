import React, { FC } from "react";
import { KeyedMutator } from "swr";
import PromptEditor from "./PromptEditor";
import MediaUploader from "./MediaUploader";
import ActionEditor from "./ActionEditor";

import { BottomArea, PreviewInner, UpperArea } from "./StepCanvas.styles";

interface ContentStepProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  section: FlowSection;
  step: ContentStep;
  viewMode: Roles;
  selectedAction: number;
  setSelectedAction: React.Dispatch<React.SetStateAction<number>>;
  isLastStep: boolean;
  locale: string;
}

const ContentStep: FC<ContentStepProps> = ({
  flow,
  mutate,
  section,
  step,
  viewMode,
  selectedAction,
  setSelectedAction,
  isLastStep,
  locale,
}) => {
  return (
    <PreviewInner>
      <UpperArea>
        <MediaUploader viewMode={viewMode} mutate={mutate} flow={flow} section={section} step={step} locale={locale} />
        <PromptEditor viewMode={viewMode} mutate={mutate} flow={flow} section={section} step={step} locale={locale} />
      </UpperArea>
      <BottomArea>
        {isLastStep ? (
          <small style={{ marginBottom: "10px" }}>
            This is the last step. <br />
            An appropriate button will appear here automatically if you don&apos;t add any action.
            <br />
            i.e. &quot;Go back to lobby&quot;, &quot;Continue to next session&quot; or &quot;End call &amp; give
            feedback&quot;
          </small>
        ) : (
          ""
        )}
        <ActionEditor
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          viewMode={viewMode}
          mutate={mutate}
          flow={flow}
          section={section}
          step={step}
          locale={locale}
        />
      </BottomArea>
    </PreviewInner>
  );
};

export default ContentStep;
