import React, { FC, useState, useRef, useEffect } from "react";
import { TextArea } from "grommet";
import styled from "styled-components";

interface Props {
  initialValue: string;
  initiallyEditing: boolean;
  valueTransformer: (s: string) => any;
  onCommit: (value: any) => void;
}

const Text = styled.div`
  white-space: pre-wrap;
  padding: 3px;
`;

const StyledTextArea = styled(TextArea)`
  border: 0px;
  display: block;
  font-size: inherit;
  text-align: inherit;
  font-weight: normal;
  padding: 4px;
  width: 100%;
  min-height: 10px;

  &:focus {
    box-shadow: 0px 0px 2px 1px #888;
  }
`;

const EditableText: FC<Props> = ({ initialValue, initiallyEditing = false, valueTransformer, onCommit }) => {
  const [editing, setEditing] = useState(initiallyEditing);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (editing) {
      inputRef.current?.select();

      if (inputRef.current) {
        inputRef.current.style.height = "";
        inputRef.current.style.height = inputRef.current.scrollHeight + "px";
      }
    }
  }, [editing]);

  return (
    <>
      {editing ? (
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            setEditing(false);
            onCommit(valueTransformer ? valueTransformer(value) : value);
          }}
        >
          <StyledTextArea
            ref={inputRef}
            autoFocus
            onBlur={() => {
              setEditing(false);
              onCommit(valueTransformer ? valueTransformer(value) : value);
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </form>
      ) : (
        <Text onDoubleClick={() => setEditing(true)}>{value}</Text>
      )}
    </>
  );
};

export default EditableText;
