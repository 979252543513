import styled from "styled-components";
import { Stack, TextInput } from "grommet";

import { Button } from "~/components/UI";

export const Preview = styled.div`
  flex: 1;
  margin-right: 15px;
  width: 100%;
  height: 100%;
`;

export const PreviewBox = styled.div`
  background-color: #fff;
  border-radius: 10px;
  min-width: 500px;
  height: calc(100% - 30px);
  position: relative;

  &.step-selected {
    height: calc(100% - 60px);
    border-bottom-left-radius: 0px;
  }
`;

export const PreviewInner = styled.div`
  position: absolute;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
`;

export const UpperArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  > button,
  label {
    width: 50%;
  }
`;

export const CenterArea = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const BottomArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  > button,
  label {
    width: 50%;
  }
`;

export const Prompt = styled.div`
  font-family: "Lora";
  font-variation-settings: "wght" 600;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  width: 80%;
  margin-top: 10px;
  min-height: 15px;
  white-space: pre-line;
`;

export const Audio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 80%;
  height: 34px;
  margin-top: 10px;
`;

export const DeleteAudio = styled.button`
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 10px;

  &:hover {
    background-color: #eee;
  }

  svg {
    width: 14px;
  }
`;

export const AudioFilename = styled.div`
  font-size: 13px;
  color: #444;
`;

export const ContentToggle = styled.div``;

export const ContentToggleButton = styled.button`
  border: 0px;
  padding: 10px;
  margin: 0px;
  background-color: #888;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  opacity: 0.5;
  margin-right: 1px;
  cursor: not-allowed;

  &:hover {
    background-color: #888;
  }

  &.active {
    background-color: #555;
  }

  &.enabled {
    cursor: pointer;
    opacity: 1;
    :hover {
      background-color: #bbb;
      &.active {
        background-color: #555;
      }
    }
  }

  :first-child {
    border-bottom-left-radius: 9px;
  }

  :last-child {
    border-bottom-right-radius: 9px;
  }
`;

export const ContentToggleInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  svg {
    width: 16px;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const ActionButton = styled(Button)`
  text-transform: none;
  font-size: 18px;
  font-weight: normal;

  padding: 10px 18px;

  &.selected {
    border: 2px dashed #993300;
  }

  &.draggable {
    pointer-events: none;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: "row";
  justify-content: center;
  padding-left: 20px;
`;

export const ActionButtonStack = styled(Stack)`
  margin-right: 20px;
  width: auto;
  flex-grow: 0;
  flex-shrink: 0;

  .editDeleteButton {
    display: none;
    background-color: #555;
    border-radius: 50px;
    width: 60px;
    height: 30px;
    position: absolute;
    top: -18px;
    right: -18px;

    align-items: center;
    justify-content: center;

    border: 0px;

    &:hover {
      background-color: #888;
      cursor: pointer;
    }

    svg {
      width: 13px;
      padding: 3px;

      &:hover {
        width: 15px;
      }
    }
  }

  &:hover {
    .editDeleteButton {
      display: flex;
    }
  }
`;

export const StyledTextInput = styled(TextInput)`
  border: 0px;
  display: block;
  font-size: 18px;
  font-weight: normal;
  padding: 0px;
  width: 100%;

  pointer-events: auto;

  &:focus {
    box-shadow: 0px 0px 2px 1px #888;
  }
`;
