import React, { FC, useEffect } from "react";
import { KeyedMutator } from "swr";
import PromptEditor from "./PromptEditor";
import { PreviewInner, UpperArea, CenterArea, ActionButtonStack, ActionButton } from "./StepCanvas.styles";
import { ChoicesList } from "./ChoicesStep";
import AddButton from "~/components/UI/AddButton";
import styled from "styled-components";
import updateStep from "~/lib/requests/updateStep";
import EditableTextForLocale from "~/components/UI/EditableTextForLocale";

interface FeedbackScoreStepProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  section: FlowSection;
  step: FeedbackScoreStep;
  viewMode: Roles;
  locale: string;
}

export const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EditScoreArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
`;

const FeedbackScoreStep: FC<FeedbackScoreStepProps> = ({ flow, mutate, section, step, viewMode, locale }) => {
  const width = step.content[viewMode].choiceListWidth || 80;
  const columns = step.content[viewMode].columns || 5;

  return (
    <PreviewInner>
      <UpperArea>
        <PromptEditor viewMode={viewMode} mutate={mutate} flow={flow} section={section} step={step} locale={locale} />
      </UpperArea>
      <CenterArea>
        <ScoreWrapper>
          <EditScoreArea style={{ width: `${width}%` }}>
            <AddButton style={{ marginBottom: "10px", padding: "10px", fontWeight: "400", maxWidth: "fit-content" }}>
              <EditableTextForLocale
                initialValue={step.content[viewMode].descriptionLow}
                onCommit={(_, value) => {
                  updateStep(mutate, flow.id, section.id, step.id, {
                    // @ts-ignore
                    content: {
                      [viewMode]: {
                        descriptionLow: value,
                      },
                    },
                  });
                }}
                placeholder={{ en: "Low / Sad" }}
                id={step.id}
                locale={locale}
              />
            </AddButton>
            <AddButton style={{ marginBottom: "10px", padding: "10px", fontWeight: "400", maxWidth: "fit-content" }}>
              <EditableTextForLocale
                initialValue={step.content[viewMode].descriptionHigh}
                onCommit={(_, value) => {
                  updateStep(mutate, flow.id, section.id, step.id, {
                    // @ts-ignore
                    content: {
                      [viewMode]: {
                        descriptionHigh: value,
                      },
                    },
                  });
                }}
                placeholder={{ en: "Uplifted / Happy" }}
                id={step.id}
                locale={locale}
              />
            </AddButton>
          </EditScoreArea>
          {
            <ChoicesList width={width} columns={columns}>
              {Array.from({ length: step.content[viewMode].maxValue }, (_, i) => (
                <ActionButtonStack anchor="top-right" key={i}>
                  <ActionButton
                    primary
                    label={i + 1}
                    size="small"
                    style={{ height: step.content[viewMode].buttonHeight || "auto " }}
                    id={"score" + i + 1}
                  />
                </ActionButtonStack>
              ))}
            </ChoicesList>
          }
        </ScoreWrapper>
      </CenterArea>
    </PreviewInner>
  );
};

export default FeedbackScoreStep;
