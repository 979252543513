import { useEffect, useState } from "react";
import { KeyedMutator } from "swr";
import { requestMapper } from "../lib/requests/requestMapper";
import { getFlowDifference, isEqual } from "~/lib/getFlowDifference";
import { Slide, toast } from "react-toastify";

/**
 * A custom hook to handle undo/redo functionality
 * @param initialValue - The initial value of the flow
 * @param mutate - The mutate function from SWR
 * @returns An array containing the history, the history index, the undo function and the redo function
 */
export const useHistory = (initialValue: FlowIndexResponse | undefined, mutate: KeyedMutator<FlowIndexResponse>) => {
  const [history, setHistory] = useState<any>([]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [currentFlow, setCurrentFlow] = useState<FlowIndexResponse>();
  const [expectedChanges, setExpectedChanges] = useState<any[]>([]);

  const setUpdatedFlow = (updatedFlow: FlowIndexResponse): void => {
    const changes = getFlowDifference(currentFlow, updatedFlow);

    setCurrentFlow(JSON.parse(JSON.stringify(updatedFlow)));

    if (currentFlow === undefined) {
      return;
    }

    // If the changes are the same as the last expected changes, remove them from the expected changes
    // This is to prevent a change that comes from undo/redo from being added to the history
    if (changes.length > 0) {
      for (let i = 0; i < expectedChanges.length; i++) {
        if (isEqual(expectedChanges[i], changes)) {
          setExpectedChanges([...expectedChanges.slice(0, i), ...expectedChanges.slice(i + 1)]);
          return;
        }
      }

      setHistory([...history.slice(0, historyIndex), changes, ...history.slice(historyIndex)]);
      setHistoryIndex(historyIndex + 1);
    }
  };

  useEffect(() => {
    initialValue && setUpdatedFlow(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // If the user is typing in an input, don't allow undo/redo
      if (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement) {
        return;
      }
      if ((e.ctrlKey && e.code === "KeyZ") || (e.metaKey && e.code === "KeyZ" && !e.shiftKey)) {
        //@ts-ignore
        const undoMessage = undo().message;

        toast(undoMessage, {
          transition: Slide,
          autoClose: 2500,
          hideProgressBar: true,
          closeButton: true,
          position: "top-center",
        });
      } else if ((e.ctrlKey && e.code === "KeyY") || (e.metaKey && e.code === "KeyZ" && e.shiftKey)) {
        //@ts-ignore
        const redoMessage = redo().message;

        toast(redoMessage, {
          transition: Slide,
          autoClose: 2500,
          hideProgressBar: true,
          closeButton: true,
          position: "top-center",
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const undo = () => {
    if (historyIndex > 0) {
      const changes = history[historyIndex - 1];
      setHistoryIndex(historyIndex - 1);

      return requestMapper(changes, true, mutate, initialValue, expectedChanges, setExpectedChanges);
    } else {
      return "No changes to undo";
    }
  };

  const redo = () => {
    if (historyIndex < history.length) {
      const changes = history[historyIndex];
      setHistoryIndex(historyIndex + 1);

      return requestMapper(changes, false, mutate, initialValue, expectedChanges, setExpectedChanges);
    } else {
      return "No changes to redo";
    }
  };

  return [history, historyIndex, undo, redo];
};
