import React, { useEffect, FC, useState, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 60%;
  margin-top: 10px;
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(33, 33, 33, 0.5);

  @media (max-width: 800px) {
    width: 80%;
  }
`;

const AudioPlayer = styled.div`
  display: flex;

  z-index: 10;

  justify-content: space-between;
  align-items: center;
`;

const PlayButton = styled.div`
  color: ${({ theme }) => theme.global.colors.brand};
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 15px;
  margin-right: 16px;
  border-color: transparent transparent transparent ${({ theme }) => theme.global.colors.brand};
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 8px 0 8px 14px;

  &.playing {
    border-style: double;
    border-width: 0px 0 0px 13px;
  }

  &:hover {
    border-color: transparent transparent transparent ${({ theme }) => theme.global.colors.brand};
  }
`;

const VideoArea = styled.div`
  position: relative;
  margin-top: 15px;

  video {
    width: 100%;
    border-radius: 8px;
  }
`;

const ProgressKnob = styled.div`
  border-radius: 100px;
  background-color: ${({ theme }) => theme.global.colors.brand};
  position: absolute;
  right: -5px;
  top: -2px;
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: all 0.2s ease;
`;

const Progress = styled.div`
  height: 10px;
  width: 100%;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    ${ProgressKnob} {
      transform: scale(1.3);
    }
  }
`;

const ProgressBar = styled.div`
  background-color: ${({ theme }) => theme.global.colors.brand};
  width: 0%;
  height: 5px;
  border-radius: 5px;
  transition: width 0.5s linear;
  position: relative;

  &.setting-time {
    transition: width 0s linear;
  }
`;

const Time = styled.div`
  font-size: 12px;
  color: #fff;
  width: 80px;
  position: relative;
  margin-left: 10px;
  white-space: nowrap;
`;

// https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds
function fancyTimeFormat(duration: number) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

interface AudioPlayerProps {
  audioFileURL: string; // The URL to the file to play.
  playing: boolean; // Whether or not the audio should be playing.
  onSetCurrentTime?: (time: number) => void; // A function that is called when the user clicks on the the timeline to jump to a different time.
  bindSetCurrentTime?: (time: number) => void; // A function that binds the internal setCurrentTime function so a parent component can use it.
  initialCurrentTime: number;
  onProgress?: (time: number) => void; // A function that is called roughly every second that the audio is playing with the current time of the audio.
  onAudioLoaded?: () => void; // A function that gets called when the audio is loaded and ready to play.
  onAudioDone?: () => void; // A function that is called when the audio has reached the end.
  audioContext: AudioContext | undefined;
  gainNode: GainNode | undefined; // Where to output the audio to.
  onPlay?: () => void;
  onPause?: () => void;
}

export default (({
  gainNode,
  audioContext,
  audioFileURL,
  playing,
  onAudioDone,
  onAudioLoaded,
  onPlay,
  onPause,
  onSetCurrentTime,
  initialCurrentTime,
}) => {
  const [media, setMedia] = useState<HTMLAudioElement | HTMLVideoElement | undefined>(undefined);
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const [settingTime, setSettingTime] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const videoArea = useRef<HTMLDivElement>(null);
  const showTime = false;

  const handleAudioDone = () => onAudioDone && onAudioDone();
  const handleAudioCanPlayThrough = () => onAudioLoaded && onAudioLoaded();
  const handleTimeUpdate = function (this: HTMLAudioElement) {
    const percentage = Math.round((this.currentTime / this.duration) * 100);
    setCurrentPercentage(percentage);
  };

  useEffect(() => {
    setIsVideo(false);
  }, [audioFileURL]);

  useEffect(() => {
    if (isVideo && media) {
      videoArea.current?.appendChild(media);
    }
  }, [isVideo]);

  useEffect(() => {
    let a: HTMLAudioElement | HTMLVideoElement | undefined;

    if (audioContext && gainNode) {
      let url = process.env.FLOW_BUILDER_API_URL + audioFileURL;
      if (audioFileURL.startsWith("http")) {
        url = audioFileURL;
      }

      if (url.split(".").pop() === "m4v") {
        a = document.createElement("video");
        a.src = url;
        setIsVideo(true);
      } else {
        a = new Audio(url);
      }

      a.crossOrigin = "anonymous";
      a.load();

      a.addEventListener("ended", handleAudioDone);
      a.addEventListener("canplaythrough", handleAudioCanPlayThrough);
      a.addEventListener("timeupdate", handleTimeUpdate);

      const audioSource = audioContext.createMediaElementSource(a);
      audioSource.connect(gainNode).connect(audioContext.destination);
      setMedia(a);
    }

    return () => {
      a?.removeEventListener("ended", handleAudioDone);
      a?.removeEventListener("canplaythrough", handleAudioCanPlayThrough);
      a?.removeEventListener("timeupdate", handleTimeUpdate);
      a?.pause();
      a?.remove();
    };
  }, [gainNode, audioFileURL, audioContext]);

  useEffect(() => {
    if (media && playing) {
      play();
    }

    if (media && !playing) {
      pause();
    }
  }, [media, playing]);

  const pause = () => {
    media?.pause();
  };

  const play = () => {
    audioContext
      ?.resume()
      .then(() => {
        media?.play().catch((e) => {
          throw e;
        });
      })
      .catch((e) => {
        throw e;
      });
  };

  const setAudioTime = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const width = rect.width;
    const x = e.clientX - rect.left;

    const desiredProportion = x / width;

    let desiredTime = 0;
    if (media) {
      desiredTime = media.duration * desiredProportion;
      onSetCurrentTime && onSetCurrentTime(desiredTime);
    }
  };

  useEffect(() => {
    if (media) {
      setTimeout(() => {
        setSettingTime(true);

        setTimeout(() => {
          media.currentTime = initialCurrentTime;

          setTimeout(() => {
            setSettingTime(false);
          }, 50);
        }, 0);
      }, 0);
    }
  }, [initialCurrentTime, media]);

  return (
    <Wrapper>
      <AudioPlayer data-testid={"audio-player"}>
        <PlayButton
          className={playing ? "playing" : ""}
          onClick={() => {
            playing ? onPause && onPause() : onPlay && onPlay();
          }}
          data-testid={"play-button"}
        />
        <Progress onClick={setAudioTime}>
          <ProgressBar style={{ width: currentPercentage + "%" }} className={settingTime ? "setting-time" : ""}>
            <ProgressKnob />
          </ProgressBar>
        </Progress>

        {showTime && (
          <Time>
            {fancyTimeFormat(media?.currentTime || 0)} / {fancyTimeFormat(media?.duration || 0)}
          </Time>
        )}
      </AudioPlayer>
      {isVideo && <VideoArea ref={videoArea}></VideoArea>}
    </Wrapper>
  );
}) as FC<AudioPlayerProps>;
