import { Button } from "grommet";
import styled from "styled-components";

const StyledButton = styled(Button).attrs({ fill: false })`
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  padding: 4px 10px;
  align-self: flex-start;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  svg {
    margin-right: 5px;
  }

  color: ${(props) => (props.secondary ? "#222" : "#fff")};
  background: ${(props) => (props.secondary ? "#ddd" : props.theme.global.colors.linearBrand)};
  border-color: transparent;

  transition: transform ease 0.1s;

  &:hover {
    transform: scale(1.05);
    box-shadow: none;
  }
`;

export default StyledButton;
