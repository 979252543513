import React, { FC } from "react";
import { Box, Select } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";
import { textForLocale } from "~/lib/textForLocale";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

const buttonActionOptions = [
  { value: "done", label: "Mark step as done" },
  { value: "goto", label: "Go to a different step" },
];

interface ChoiceDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: ChoicesStep;
  choiceIndex: number;

  viewMode: Roles;
  locale: string;
}

const ChoiceDetails: FC<ChoiceDetailsProps> = ({ flow, section, step, mutate, choiceIndex, viewMode, locale }) => {
  const flattenedSteps: AnyStep[] = [];

  flow.sections.forEach((section) => {
    section.steps.forEach((step) => {
      const stepWithSection = Object.assign({}, { sectionTitle: section.title["en"] }, step);
      flattenedSteps.push(stepWithSection);
    });
  });

  const stepOptions = flattenedSteps.map((step) => {
    return { value: step.id, label: textForLocale(step.title, locale), selectValue: step.id.toString() };
  });

  let buttonActionSelectValue: "done" | "goto" = "done";

  if (choiceIndex > -1 && typeof step.content[viewMode].choices?.[choiceIndex]?.onClick === "number") {
    buttonActionSelectValue = "goto";
  }

  if (choiceIndex > -1 && step.content[viewMode].choices?.[choiceIndex]?.onClick === "done") {
    buttonActionSelectValue = "done";
  }

  return (
    <>
      <Label>Button action</Label>

      <Select
        options={buttonActionOptions}
        value={buttonActionSelectValue}
        valueKey={{ key: "value", reduce: true }}
        labelKey="label"
        size="small"
        onChange={({ option }) => {
          if (choiceIndex < 0) return;

          let newOnClick: "done" | number = "done";
          if (option.value === "goto") {
            newOnClick = 0;
          }

          const newContent = step.content[viewMode];
          newContent.choices = newContent.choices || [];
          newContent.choices[choiceIndex] = {
            text: newContent.choices[choiceIndex]?.text,
            image: newContent.choices[choiceIndex]?.image,
            onClick: newOnClick,
          };

          //@ts-ignore
          updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
        }}
        a11yTitle="A drop down to select what this button should do when it is clicked"
      />

      {buttonActionSelectValue === "goto" && (
        <>
          <br />
          <br />
          <Label>Step</Label>

          <Select
            options={stepOptions}
            value={step.content[viewMode].choices[choiceIndex].onClick.toString()}
            labelKey="label"
            valueKey={{ key: "selectValue", reduce: true }}
            size="small"
            onChange={({ option }) => {
              if (choiceIndex < 0) return;

              const newOnClick = option.value;

              const newContent = step.content[viewMode];
              newContent.choices = newContent.choices || [];
              newContent.choices[choiceIndex] = {
                text: newContent.choices[choiceIndex]?.text,
                image: newContent.choices[choiceIndex]?.image,
                onClick: newOnClick,
              };

              //@ts-ignore
              updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
            }}
            a11yTitle="A drop down to select which step we should jump to"
          />
        </>
      )}
    </>
  );
};

export default ChoiceDetails;
