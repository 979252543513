import styled from "styled-components";

const Badge = styled.div`
  display: inline-block;
  background-color: #6241aa;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  padding: 0px 8px;
  margin-left: 10px;
  position: relative;
`;

export default Badge;
