import React, { FC } from "react";
import styled from "styled-components";
import { Spinner } from "grommet";

const LoadingWrapper = styled.div`
  box-sizing: border-box;
  height: 100vh;
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 50%;

  &.loading-flows {
    height: 70vh;
  }
`;

const LoadingInner = styled.div`
  left: 50%;
  margin: auto;
  margin: -25px 0 0 -25px;
  position: relative;
  text-align: center;
  top: 50%;
  width: 30px;
`;

interface Props {
  className?: string;
}

export const Loading: FC<Props> = ({ className }) => {
  return (
    <LoadingWrapper className={className}>
      <LoadingInner>
        <Spinner />
      </LoadingInner>
    </LoadingWrapper>
  );
};
