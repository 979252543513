import React, { FC } from "react";
import { Box, Select } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";
import { DEFAULT_LOCALE } from "~/lib/defaults";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface AutomationStepDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: BranchStep;

  viewMode: Roles;
}

const BranchStepDetails: FC<AutomationStepDetailsProps> = ({ flow, section, step, mutate }) => {
  const flattenedSteps: AnyStep[] = [];

  flow.sections.forEach((section) => {
    section.steps.forEach((step) => {
      const stepWithSection = Object.assign({}, { sectionTitle: section.title["en"] }, step);
      flattenedSteps.push(stepWithSection);
    });
  });

  const stepOptions = flattenedSteps.map((step) => {
    return { value: step.id, label: step.title[DEFAULT_LOCALE], selectValue: step.id.toString() };
  });

  return (
    <>
      <Label>Skip next step if everyone completed:</Label>
      <Select
        options={stepOptions}
        value={step.settings.stepID?.toString()}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          updateStep(mutate, flow.id, section.id, step.id, {
            // @ts-ignore
            settings: { conditional: "allCompleted", stepID: option.value },
          });
        }}
        a11yTitle="A drop down to select which step we should jump to"
      />
    </>
  );
};

export default BranchStepDetails;
