import updateSection from "~/lib/requests/updateSection";
import { KeyedMutator } from "swr";

export default async (
  flow: FlowIndexResponse,
  section: FlowSection,
  sourceIndex: number,
  destinationIndex: number,
  draggableID: number,
  mutate: KeyedMutator<FlowIndexResponse>
) => {
  const stepIDs = section.steps.map((s) => s.id);

  stepIDs.splice(sourceIndex, 1);
  stepIDs.splice(destinationIndex, 0, draggableID);

  const newSection: FlowSection = {
    ...section,

    steps: stepIDs
      .map((sID) => {
        return section.steps.find((s) => s.id === sID);
      })
      .filter((s): s is AnyStep => !!s),
  };

  const newFlow: FlowIndexResponse = {
    ...flow,
  };

  const sectionIndex = newFlow.sections.findIndex((s) => s.id === section.id);
  newFlow.sections = Array.from(flow.sections);
  newFlow.sections[sectionIndex] = newSection;

  mutate(newFlow, false);

  updateSection(mutate, flow, section.id, { steps: stepIDs });
};
