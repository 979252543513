import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default (flowID: number, role: "speaker" | "listener", mutate: KeyedMutator<FlowIndexResponse>) => {
  fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/background_image/${role}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => {
      mutate();
    })
    .catch(() => {
      toast(
        <>
          <b>Unable to remove background image</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
        </>
      );
    });
};
