import updateFlowSections from "~/lib/requests/updateFlowSections";
import { KeyedMutator } from "swr";

export default async (
  flow: FlowIndexResponse,
  sourceIndex: number,
  destinationIndex: number,
  draggableID: number,
  mutate: KeyedMutator<FlowIndexResponse>
) => {
  const sectionIDs = flow.sections.map((s) => s.id);

  sectionIDs.splice(sourceIndex, 1);
  sectionIDs.splice(destinationIndex, 0, draggableID);

  const newFlow: FlowIndexResponse = {
    ...flow,
    sections: sectionIDs
      .map((sID) => {
        return flow.sections.find((s) => s.id === sID);
      })
      .filter((s): s is FlowSection => !!s),
  };

  mutate(newFlow, false);

  await updateFlowSections(flow, sectionIDs);

  mutate();
};
