"use strict";

exports.__esModule = true;
exports.base = void 0;
var base = exports.base = {
  global: {
    colors: {
      icon: '#666666'
    }
  },
  icon: {
    size: {
      small: '12px',
      medium: '24px',
      large: '48px',
      xlarge: '96px'
    }
  }
};