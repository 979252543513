import React, { FC, useEffect, useRef, useState } from "react";
import { Clock, InProgress } from "grommet-icons";
import { Select, Text, Box, TextInput } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";

import updateSection from "~/lib/requests/updateSection";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface SectionDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
}

const enforceDurationOptions = [
  { value: true, label: "Yes", selectValue: "true" },
  { value: false, label: "No", selectValue: "false" },
];

const SectionDetails: FC<SectionDetailsProps> = ({ flow, section, mutate }) => {
  const durationInput = useRef<HTMLInputElement>(null);

  const [duration, setDuration] = useState<string>("");

  useEffect(() => {
    const duration = (section.durationSeconds && (section.durationSeconds / 60).toString()) || "0";

    setDuration(duration);
  }, [section.durationSeconds]);

  const updateDuration = async (timing: string) => {
    if (timing === "") {
      timing = "0";
    }
    if (flow && section) {
      updateSection(mutate, flow, section.id, { durationSeconds: parseInt(timing) * 60 });
    }
  };

  return (
    <>
      {" "}
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <InProgress />
          Enforce duration?
        </Label>
      </Text>
      <Select
        options={enforceDurationOptions}
        value={section.enforceDuration ? "true" : "false"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={async ({ option }) => {
          updateSection(mutate, flow, section.id, { enforceDuration: option.value });
        }}
        a11yTitle="A drop down to select whether or not the flow should automatically advance to the next section when the time is up"
      />
      <br />
      <br />
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <Clock />
          Duration (minutes):
        </Label>
      </Text>
      <Box direction="row" align="center">
        <TextInput
          suggestions={["0", "3", "5", "10", "15", "20", "30"]}
          onSuggestionSelect={(e) => {
            setDuration(e.suggestion);
            durationInput.current?.blur();
          }}
          type="number"
          style={{ maxWidth: "100px", width: "100px" }}
          ref={durationInput}
          value={duration}
          onChange={(e) => {
            setDuration(e.target.value);
          }}
          onBlur={(e) => {
            updateDuration(e.target.value);
          }}
        />
      </Box>
      Set to &quot;0&quot; to disable duration hints
    </>
  );
};

export default SectionDetails;
