import React, { FC, useRef } from "react";
import { Text, Box, TextInput } from "grommet";
import { Apps, Shift } from "grommet-icons";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface FeedbackScoreDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: FeedbackScoreStep;
  viewMode: Roles;
}

const FeedbackScoreDetails: FC<FeedbackScoreDetailsProps> = ({ mutate, section, step, viewMode, flow }) => {
  const choicesColumnsInput = useRef<HTMLInputElement>(null);
  const choiceListWidthInput = useRef<HTMLInputElement>(null);
  const maxValueInput = useRef<HTMLInputElement>(null);

  const setMaxValue = (maxValue: number) => {
    if (flow && section && step) {
      const newContent: FeedbackScoreStepContent = Object.assign({}, step.content[viewMode]);
      newContent.maxValue = maxValue;
      //@ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  const setChoicesColumns = (count: string) => {
    if (flow && section && step) {
      const newContent: FeedbackScoreStepContent = Object.assign({}, step.content[viewMode]);
      newContent.columns = parseInt(count);

      //@ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  const setChoiceListWidth = (number: string) => {
    if (flow && section && step) {
      const newContent: FeedbackScoreStepContent = Object.assign({}, step.content[viewMode]);
      newContent.choiceListWidth = parseInt(number);

      //@ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  const setButtonHeight = (number: string) => {
    if (flow && section && step) {
      const newContent: FeedbackScoreStepContent = Object.assign({}, step.content[viewMode]);
      newContent.buttonHeight = parseInt(number);

      //@ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  return (
    <>
      <h3>Feedback Score settings</h3>
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Apps />
            Score Max Value
          </div>
        </Label>
      </Text>
      <Box direction="row" align="center" justify="start" margin={{ bottom: "small" }}>
        <Box margin={{ right: "small" }}>
          <TextInput
            suggestions={["3", "5", "10"]}
            onSuggestionSelect={(e) => {
              setMaxValue(parseInt(e.suggestion));
              maxValueInput.current?.blur();
            }}
            ref={maxValueInput}
            type="number"
            style={{ maxWidth: "100px", width: "100px" }}
            value={step.content[viewMode].maxValue || 10}
            onChange={(e) => {
              if (e.target.value) {
                setMaxValue(parseInt(e.target.value));
                updateStep(mutate, flow.id, section.id, step.id, {
                  // @ts-ignore
                  content: {
                    [viewMode]: {
                      ...step.content[viewMode],
                      maxValue: parseInt(e.target.value),
                    },
                  },
                });
              }
            }}
          />
        </Box>
      </Box>
      Defines the maximum value of the score
      <br />
      <br />
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Apps />
            Choice Columns
          </div>
        </Label>
      </Text>
      <Box direction="row" align="center">
        <TextInput
          suggestions={["3", "5", "10"]}
          onSuggestionSelect={(e) => {
            setChoicesColumns(e.suggestion);
            choicesColumnsInput.current?.blur();
          }}
          type="number"
          style={{ maxWidth: "100px", width: "100px" }}
          ref={choicesColumnsInput}
          value={step.content[viewMode].columns || 10}
          onChange={(e) => {
            if (e.target.value) {
              setChoicesColumns(e.target.value);
            }
          }}
        />
      </Box>
      <br />
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Shift />
            Choice List Width
          </div>
        </Label>
      </Text>
      <Box direction="row" align="center">
        <TextInput
          suggestions={["60%", "70%", "80%", "90%"]}
          onSuggestionSelect={(e) => {
            setChoiceListWidth(e.suggestion);
            choiceListWidthInput.current?.blur();
          }}
          type="number"
          style={{ maxWidth: "100px", width: "100px" }}
          ref={choiceListWidthInput}
          value={step.content[viewMode].choiceListWidth || 80}
          onChange={(e) => {
            if (e.target.value) {
              setChoiceListWidth(e.target.value);
            }
          }}
        />
      </Box>
      <br />
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Shift />
            Button Height
          </div>
        </Label>
      </Text>
      <Box direction="row" align="center">
        <TextInput
          suggestions={["auto", "50", "100", "200"]}
          onSuggestionSelect={(e) => {
            setButtonHeight(e.suggestion);
            choiceListWidthInput.current?.blur();
          }}
          style={{ maxWidth: "100px", width: "100px" }}
          ref={choiceListWidthInput}
          value={step.content[viewMode].buttonHeight || "auto"}
          onChange={(e) => {
            if (e.target.value) {
              setButtonHeight(e.target.value);
            }
          }}
        />
      </Box>
      <br />
    </>
  );
};

export default FeedbackScoreDetails;
