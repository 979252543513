import React, { FC } from "react";
import { PreviewInner, UpperArea } from "./StepCanvas.styles";
import { KeyedMutator } from "swr";
import PromptEditor from "./PromptEditor";

interface IframeStepProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  section: FlowSection;
  step: IframeStep;
  viewMode: Roles;
  locale: string;
}

const IframeStep: FC<IframeStepProps> = ({ flow, mutate, section, step, viewMode, locale }) => {
  return (
    <PreviewInner>
      <UpperArea>
        <PromptEditor viewMode={viewMode} mutate={mutate} flow={flow} section={section} step={step} locale={locale} />
      </UpperArea>
    </PreviewInner>
  );
};

export default IframeStep;
