import updateSection from "~/lib/requests/updateSection";
import { KeyedMutator } from "swr";

export default async (
  flow: FlowIndexResponse,
  sourceSection: FlowSection,
  destinationSection: FlowSection,
  insertAtIndex: number,
  step: AnyStep,
  mutate: KeyedMutator<FlowIndexResponse>,
  setSelectedStep: (arg: { sectionID: number; stepID: number } | undefined) => void
) => {
  // Remove step from source section.
  const sourceStepIDs = sourceSection.steps.map((s) => s.id);
  const index = sourceStepIDs.indexOf(step.id);
  if (index !== -1) {
    sourceStepIDs.splice(index, 1);
  }

  const newFlow: FlowIndexResponse = { ...flow };
  const newSourceSection: FlowSection = {
    ...sourceSection,

    steps: sourceStepIDs
      .map((sID) => {
        return sourceSection.steps.find((s) => s.id === sID);
      })
      .filter((s): s is AnyStep => !!s),
  };

  const sourceSectionIndex = newFlow.sections.findIndex((s) => s.id === sourceSection.id);
  newFlow.sections = Array.from(flow.sections);
  newFlow.sections[sourceSectionIndex] = newSourceSection;

  // Add step to destination section.
  const destinationStepIDs = destinationSection.steps.map((s) => s.id);
  destinationStepIDs.splice(insertAtIndex, 0, step.id);

  const newDestinationSection: FlowSection = {
    ...destinationSection,

    steps: destinationStepIDs.map((sID) => {
      return destinationSection.steps.find((s) => s.id === sID) || step;
    }),
  };

  const destinationSectionIndex = newFlow.sections.findIndex((s) => s.id === destinationSection.id);
  newFlow.sections[destinationSectionIndex] = newDestinationSection;

  setSelectedStep({ sectionID: destinationSection.id, stepID: step.id });
  mutate(newFlow, false);

  updateSection(mutate, flow, sourceSection.id, { steps: sourceStepIDs });
  updateSection(mutate, flow, destinationSection.id, { steps: destinationStepIDs });
};
