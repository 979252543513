import React, { FC } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import { Button } from "~/components/UI";
import { KeyedMutator } from "swr";
import { textForLocale } from "~/lib/textForLocale";
import { useNavigate } from "react-router-dom";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: AnyStep;
  deleteStep: (flowID: number, sectionID: number, stepID: number, mutate: KeyedMutator<FlowIndexResponse>) => void;
  mutate: KeyedMutator<FlowIndexResponse>;
  locale: string;
}

const DeleteStep: FC<Props> = ({ visible, setVisible, flow, section, step, deleteStep, mutate, locale }) => {
  const navigate = useNavigate();

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            Delete &quot;{textForLocale(step.title, locale)}&quot;
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "small" }}>
          <Text size="small">Are you sure you want to delete this step?</Text>
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

          <Button
            primary
            size="small"
            label="Delete Step"
            onClick={() => {
              deleteStep(flow.id, section.id, step.id, mutate);
              navigate("/session-flow/" + flow.id + "/section/" + section.id);

              setVisible(false);
            }}
          />
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default DeleteStep;
