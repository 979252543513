import React, { FC } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import { Button } from "~/components/UI";
import { LanguageOptions } from "~/components/UI/LanguageSelector";
import { KeyedMutator } from "swr";
import deleteTranslation from "~/lib/requests/deleteTranslation";
import { DEFAULT_LANGUAGE, DEFAULT_LOCALE } from "~/lib/defaults";
import updateFlow from "~/lib/requests/updateFlow";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  flow: FlowIndexResponse;
  localeToDelete: LanguageOptions;
  setSelectedLocale: (locale: string) => void;
  setLocale: (locale: string) => void;
  mutate: KeyedMutator<FlowIndexResponse>;
  setTranslatedLanguages: (locales: string[]) => void;
  translatedLanguages: string[];
}

const DeleteTranslation: FC<Props> = ({
  visible,
  setVisible,
  flow,
  localeToDelete,
  setSelectedLocale,
  setLocale,
  mutate,
  setTranslatedLanguages,
  translatedLanguages,
}) => {
  const handleDeleteTranslation = () => {
    const newTranslatedLocales = translatedLanguages.filter((language) => language !== localeToDelete.value);
    setTranslatedLanguages(newTranslatedLocales);

    deleteTranslation(flow, localeToDelete.value, mutate);
    updateFlow(flow, { translatedLanguages: newTranslatedLocales }, mutate);

    setSelectedLocale(DEFAULT_LOCALE);
    setLocale(DEFAULT_LANGUAGE);

    setVisible(false);
  };

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Card width="medium" background="light-1">
        <CardHeader pad="small">
          <Text size="small" style={{ fontWeight: 600 }}>
            Delete Translation in {localeToDelete.label}
          </Text>
        </CardHeader>
        <CardBody pad={{ horizontal: "small", bottom: "small" }}>
          <Text size="small">
            Are you sure you want to delete all the translations in {localeToDelete.label} for this flow?
          </Text>
          <br />
          <Text size="small">The default language will be used instead.</Text>
        </CardBody>
        <CardFooter pad="small" background="light-2">
          <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

          <Button primary size="small" label="Delete Translation" onClick={handleDeleteTranslation} />
        </CardFooter>
      </Card>
    </Layer>
  );
};

export default DeleteTranslation;
