/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC } from "react";
import { PreviewInner } from "./StepCanvas.styles";
import { CheckboxSelected } from "grommet-icons";
import styled from "styled-components";

const AutomationStepInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: auto;
`;

interface MarkStepCompleteProps {}

const MarkStepComplete: FC<MarkStepCompleteProps> = () => {
  return (
    <PreviewInner>
      <AutomationStepInfo>
        <CheckboxSelected />
        <br />
        <small>Mark step complete</small>
        The &apos;Mark Step Complete&apos; step lets you mark the step complete for the person that currently has Role
        A.
      </AutomationStepInfo>
    </PreviewInner>
  );
};

export default MarkStepComplete;
