import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default (
  mutate: KeyedMutator<FlowIndexResponse>,
  setSelectedStep: (arg: { sectionID: number; stepID: number }) => void,
  flowID: number,
  sectionID: number,
  title: LocaleText,
  type: string
) => {
  fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/sections/${sectionID}/steps`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ title, type }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then((step) => {
      mutate();
      setSelectedStep({ sectionID: sectionID, stepID: step.id });
    })
    .catch(() => {
      toast(
        <>
          <b>Unable to create step</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
        </>
      );
    });
};
