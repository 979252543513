import React, { FC, useState } from "react";
import { Box, TextInput, Layer, Card, CardHeader, CardBody, CardFooter, Text, Select } from "grommet";
import { Button } from "~/components/UI";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";
import { textForLocale } from "~/lib/textForLocale";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: ContentStep;
  mutate: KeyedMutator<FlowIndexResponse>;
  viewMode: Roles;
  locale: string;
}

const Form = styled.form`
  font-size: 14px;
`;

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

const actionConsequenceOptions = [
  { value: "done", label: "Mark step as done" },
  { value: "goto", label: "Go to a specific step" },
  { value: "userFeedback", label: "Redirects to feedback URL." },
  // { value: "nothing", label: "Do nothing" },
];

const NewAction: FC<Props> = ({ visible, setVisible, mutate, flow, section, step, viewMode, locale }) => {
  const [textValue, setTextValue] = useState("Done");
  const [actionConsequenceValue, setActionConsequenceValue] = useState<"done" | "goto" | "nothing" | number>("done");
  const [selectedStep, setSelectedStep] = useState<{ value: number; label: string } | undefined>(undefined);

  const flattenedSteps: AnyStep[] = [];

  flow.sections.forEach((section) => {
    section.steps.forEach((selectableSteps) => {
      // We don't want to show the current step as an option
      if (selectableSteps.id === step.id) return;
      const stepWithSection = Object.assign({}, { sectionTitle: section.title["en"] }, selectableSteps);
      flattenedSteps.push(stepWithSection);
    });
  });

  const stepOptions = flattenedSteps.map((step) => {
    return {
      value: step.id,
      label: textForLocale(step.title, locale),
    };
  });

  const addAction = () => {
    const content = Object.assign({}, step.content[viewMode]);
    content.actions = content.actions || [];
    content.actions.push({
      buttonText: {
        [locale]: textValue,
      },
      onClick: actionConsequenceValue === "goto" && selectedStep ? selectedStep?.value : actionConsequenceValue,
    });

    const value = {
      content: { [viewMode]: content },
    };

    //@ts-ignore
    updateStep(mutate, flow.id, section.id, step.id, value);

    setTextValue("Done");
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setVisible(false);
          addAction();
        }}
      >
        <Card width="medium" background="light-1">
          <CardHeader pad="small">
            <Text size="small" style={{ fontWeight: 600 }}>
              Add an action
            </Text>
          </CardHeader>
          <CardBody pad={{ horizontal: "small", bottom: "small" }}>
            <Label>Button Text:</Label>
            <TextInput
              placeholder="Done"
              value={textValue}
              onChange={(event) => setTextValue(event.target.value)}
              style={{ marginBottom: "30px" }}
              onFocus={handleFocus}
              autoFocus
            />

            <Text size="small" margin={{ bottom: "xsmall" }}>
              <Label>When the button is clicked:</Label>
            </Text>
            <Select
              options={actionConsequenceOptions}
              value={actionConsequenceValue}
              labelKey="label"
              valueKey={{ key: "value", reduce: true }}
              size="small"
              onChange={({ option }) => {
                setActionConsequenceValue(option.value);
              }}
              a11yTitle="A drop down to select what should happen when this button is pressed"
            />

            <br />
            {actionConsequenceValue === "goto" && (
              <>
                <Label>Step</Label>
                <Select
                  options={stepOptions}
                  value={selectedStep?.label}
                  labelKey="label"
                  valueKey={{ key: "label", reduce: true }}
                  size="small"
                  onChange={({ option }) => {
                    setSelectedStep(option);
                  }}
                  a11yTitle="A drop down to select which step we should jump to"
                />
              </>
            )}
            <br />
          </CardBody>
          <CardFooter pad="small" background="light-2">
            <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

            <Button
              primary
              size="small"
              label="Add Action"
              type="submit"
              onClick={() => {
                addAction();
                setVisible(false);
              }}
            />
          </CardFooter>
        </Card>
      </Form>
    </Layer>
  );
};

export default NewAction;
