import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default async (
  mutate: KeyedMutator<FlowIndexResponse>,
  flowID: number,
  sectionID: number,
  id: number,
  title: LocaleText
) => {
  return fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/sections/${sectionID}/steps/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ title }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => mutate())
    .catch(() => {
      toast(
        <>
          <b>Error re-naming step</b>
          <br />
          It was not possible to re-name this step due to an error. Please try again later or contact support.
        </>
      );
    });
};
