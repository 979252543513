import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { Box, Button, Select, Text } from "grommet";
import Flags from "country-flag-icons/react/3x2";
import { FormAdd, Language, Trash } from "grommet-icons";
import AddButton from "~/components/UI/AddButton";
import DeleteTranslation from "../FlowBuilder/Modals/DeleteTranslation";
import AddLanguage from "../FlowBuilder/Modals/AddLanguage";
import { KeyedMutator } from "swr";

const StyledOption = styled(Text)`
  &.last-translated {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    font-weight: 500;
  }

  &.translated {
    font-weight: 500;
  }
`;

const DeleteButton = styled(Button)`
  padding: 0px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;

  &:hover {
    background: #f9f6f9;
  }
`;

export type LanguageOptions = { label: string; value: string; isButtonElement?: boolean };

interface LanguageSelectorProps {
  locale: string;
  languageOptions: LanguageOptions[];
  languages: Language[] | undefined;
  setLocale: (locale: string) => void;
  setSelectedLocale: (locale: string) => void;
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
}

export const missingLocaleMap = {
  English: "GB",
  Japanese: "JP",
  Chinese: "CN",
  Arabic: "PS",
  Hindi: "IN",
  Indonesian: "ID",
  Swedish: "SE",
  Ukrainian: "UA",
  Urdu: "PK",
  Slovene: "SI",
  Bangla: "BD",
} as { [key: string]: string };

const LanguageSelector: FC<LanguageSelectorProps> = ({
  locale,
  languageOptions,
  languages,
  setLocale,
  setSelectedLocale,
  flow,
  mutate,
}) => {
  const [localeToDelete, setLocaleToDelete] = React.useState<LanguageOptions | undefined>(undefined);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showAddLanguageModal, setShowAddLanguageModal] = React.useState(false);
  const [translatedLanguages, setTranslatedLanguages] = React.useState<string[]>(flow.translatedLanguages);

  const selectedLanguage = languages?.find((language) => language.name === locale);

  const alphabeticalOrder = (a: { label: string; value: string }, b: { label: string; value: string }) => {
    if (a.label < b.label) {
      return -1;
    }
    return 1;
  };

  useEffect(() => {
    if (!flow.translatedLanguages.includes(selectedLanguage?.code as string)) {
      setSelectedLocale("en");
      setLocale("English");
    }

    setTranslatedLanguages(flow.translatedLanguages);
  }, [flow.translatedLanguages]);

  const getLocale = (locale: string) => {
    if (Object.keys(missingLocaleMap).includes(locale)) {
      return missingLocaleMap[locale];
    } else {
      if (selectedLanguage) {
        return selectedLanguage.code.toUpperCase();
      }
    }
  };

  // @ts-ignore
  const Flag = Flags[getLocale(locale)];

  const renderValueLabel = () => {
    if (selectedLanguage) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          {Flag ? (
            <Flag
              title={selectedLanguage.name}
              width={"30px"}
              height={"30px"}
              style={{
                margin: "2px 10px 2px 10px",
              }}
              data-testid={`${selectedLanguage.name}-flag`}
            />
          ) : (
            <Language
              size="small"
              style={{
                margin: "6px 5px 5px 10px",
              }}
            />
          )}{" "}
          &nbsp;
          <Text size="medium" style={{ lineHeight: "35px", fontSize: "14px", fontWeight: "600" }}>
            {selectedLanguage.name.toUpperCase()}
          </Text>
        </div>
      );
    }
    return <span>{locale}</span>;
  };

  const getLanguageOptions = (locales: string[]) => {
    return locales.map((locale) => {
      return {
        label: languages?.find((language) => language.code === locale)?.name as string,
        value: locale,
      };
    });
  };

  const untranslatedLanguages = languageOptions.filter((language) => {
    return !translatedLanguages.includes(language.value);
  });
  untranslatedLanguages.sort((a, b) => alphabeticalOrder(a, b));

  const translatedOptions = getLanguageOptions(translatedLanguages).sort((a, b) => alphabeticalOrder(a, b));
  translatedOptions.map((option, index) => {
    if (option.value === "en") {
      translatedOptions.splice(index, 1);
      translatedOptions.unshift(option);
    }
  });

  const buttonElement = {} as LanguageOptions;
  buttonElement.isButtonElement = true;

  return (
    <>
      <Select
        size={"30px 200px"}
        options={translatedOptions.concat([buttonElement])}
        value={locale}
        valueLabel={renderValueLabel}
        dropHeight="small"
      >
        {(option) => (
          <>
            {option.isButtonElement ? (
              <>
                <Box direction="row" align="center" pad="small" justify="center">
                  <AddButton
                    className="add-language"
                    onClick={() => {
                      setShowAddLanguageModal(true);
                    }}
                  >
                    <FormAdd size="16px" />
                    Add Language
                  </AddButton>
                </Box>
              </>
            ) : (
              <Box
                direction="row"
                align="center"
                pad="small"
                justify="between"
                onClick={() => {
                  setLocale(option.label);
                  setSelectedLocale(option.value as string);
                }}
              >
                <StyledOption data-testid={`${option.value}-option`}>{option.label}</StyledOption>
                {option.value !== "en" && (
                  <DeleteButton>
                    <Trash
                      size="small"
                      onClick={() => {
                        setLocaleToDelete(option);
                        setShowDeleteModal(true);
                      }}
                    />
                  </DeleteButton>
                )}
              </Box>
            )}
          </>
        )}
      </Select>

      {showDeleteModal && localeToDelete && (
        <DeleteTranslation
          setVisible={setShowDeleteModal}
          localeToDelete={localeToDelete}
          flow={flow}
          visible={true}
          setSelectedLocale={setSelectedLocale}
          setLocale={setLocale}
          mutate={mutate}
          setTranslatedLanguages={setTranslatedLanguages}
          translatedLanguages={translatedLanguages}
        />
      )}
      {showAddLanguageModal && (
        <AddLanguage
          setVisible={setShowAddLanguageModal}
          visible={true}
          untranslatedLanguages={untranslatedLanguages.sort((a, b) => alphabeticalOrder(a, b))}
          setSelectedLocale={setSelectedLocale}
          setLocale={setLocale}
          setTranslatedLanguages={setTranslatedLanguages}
          translatedLanguages={translatedLanguages}
          flow={flow}
          mutate={mutate}
        />
      )}
    </>
  );
};

export default LanguageSelector;
