import React, { useState, FC, useRef, useEffect } from "react";
import { Edit, FormAdd, Trash } from "grommet-icons";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";
import NewAction from "../Modals/NewAction";
import AddButton from "~/components/UI/AddButton";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Text } from "grommet";

import { ActionButton, ActionButtons, ActionButtonStack, StyledTextInput } from "./StepCanvas.styles";
import { textForLocale } from "~/lib/textForLocale";
import classNames from "classnames";

interface ActionEditorProps {
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  section: FlowSection;
  step: ContentStep;
  viewMode: Roles;
  selectedAction: number;
  setSelectedAction: React.Dispatch<React.SetStateAction<number>>;
  locale: string;
}

const ActionEditor: FC<ActionEditorProps> = ({
  flow,
  mutate,
  section,
  step,
  viewMode,
  selectedAction,
  setSelectedAction,
  locale,
}) => {
  const [showNewActionDialog, setShowNewActionDialog] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [selectedToEdit, setSelectedToEdit] = useState<number>(-1);
  const [currentButtonText, setCurrentButtonText] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const deleteAction = (actionIndex: number) => {
    if (flow && section && step) {
      const newActions: Action[] = [...step.content[viewMode].actions];
      const newContent: BasicStepContent = Object.assign({}, step.content[viewMode]);
      newActions.splice(actionIndex, 1);
      newContent.actions = newActions;

      // @ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  const editAction = () => {
    if (step.content[viewMode].actions[selectedToEdit]) {
      const newActions: Action[] = [...step.content[viewMode].actions];
      const newContent: BasicStepContent = Object.assign({}, step.content[viewMode]);
      newActions[selectedToEdit].buttonText[locale] = currentButtonText;
      newContent.actions = newActions;

      // @ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  useEffect(() => {
    if (selectedToEdit !== -1 && step.content[viewMode].actions[selectedToEdit]) {
      setCurrentButtonText(textForLocale(step.content[viewMode].actions[selectedToEdit].buttonText, locale));
    }
  }, [selectedToEdit, locale, step]);

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newActions: Action[] = [...step.content[viewMode].actions];
    const [reorderedItem] = newActions.splice(result.source.index, 1);
    newActions.splice(result.destination.index, 0, reorderedItem);

    step.content[viewMode].actions = newActions;

    const newContent: BasicStepContent = Object.assign({}, step.content[viewMode]);
    newContent.actions = newActions;

    // @ts-ignore
    updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
  };

  const actionButtonsClass = (index: number) =>
    classNames({
      selected: selectedAction === index,
      draggable: true,
    });

  return (
    <>
      {showNewActionDialog && (
        <NewAction
          flow={flow}
          section={section}
          step={step}
          visible={!!showNewActionDialog}
          setVisible={setShowNewActionDialog}
          viewMode={viewMode}
          mutate={mutate}
          locale={locale}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="actionButtons" direction="horizontal" isDropDisabled={editing}>
          {(provided) => (
            <ActionButtons className="actionButtons" ref={provided.innerRef} {...provided.droppableProps}>
              {step.content[viewMode].actions.map((action: Action, i) => {
                return (
                  <Draggable draggableId={`action-${i}`} index={i} key={"button" + i} isDragDisabled={editing}>
                    {(provided) => (
                      <ActionButtonStack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        anchor="top-right"
                        key={"button" + i}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAction(i);
                        }}
                      >
                        <ActionButton primary className={actionButtonsClass(i)} size="small">
                          {editing && selectedToEdit === i ? (
                            <form
                              style={{ width: "100%" }}
                              onSubmit={(e) => {
                                e.preventDefault();
                                editAction();
                                setEditing(false);
                              }}
                            >
                              <StyledTextInput
                                ref={inputRef}
                                autoFocus
                                onBlur={() => {
                                  setEditing(false);
                                  editAction();
                                }}
                                value={currentButtonText}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setCurrentButtonText(e.target.value);
                                }}
                              />
                            </form>
                          ) : (
                            <Text>{textForLocale(action.buttonText, locale)}</Text>
                          )}
                        </ActionButton>
                        {!editing || selectedToEdit !== i ? (
                          <button className="editDeleteButton">
                            <Edit
                              className="editIcon"
                              color="white"
                              onClick={() => {
                                setEditing(true);
                                setSelectedToEdit(i);
                              }}
                              a11yTitle="Edit action"
                            />
                            &nbsp;
                            <Trash
                              className="deleteIcon"
                              color="white"
                              onClick={() => deleteAction(i)}
                              a11yTitle="Delete action"
                            />
                          </button>
                        ) : null}
                      </ActionButtonStack>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ActionButtons>
          )}
        </Droppable>
      </DragDropContext>
      <AddButton onClick={() => setShowNewActionDialog(true)}>
        <FormAdd />
        Add Actions
      </AddButton>
    </>
  );
};

export default ActionEditor;
