import React, { FC, useState, useRef, useEffect } from "react";
import { TextInput } from "grommet";
import styled from "styled-components";
import { textForLocale } from "~/lib/textForLocale";

interface Props {
  initialValue: LocaleText;
  id: number;
  onCommit: (id: number, title: LocaleText) => void;
  startEditing?: boolean;
  locale: string;
  placeholder?: LocaleText;
}

const Title = styled.div`
  padding: 3px;
  width: 100%;
`;

const StyledTextInput = styled(TextInput)`
  border: 0px;
  display: block;
  font-size: 16px;
  font-weight: normal;
  padding: 4px;
  width: 100%;

  &:focus {
    box-shadow: 0px 0px 2px 1px #888;
  }
`;

const EditableText: FC<Props> = ({ initialValue, id, onCommit, startEditing, locale, placeholder }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    //Updating the initial value if it changes through the undo/redo
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setEditing(!!startEditing);
  }, [startEditing]);

  useEffect(() => {
    if (editing) {
      inputRef.current?.select();
    }
  }, [editing]);

  return (
    <>
      {editing ? (
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            setEditing(false);
            onCommit(id, value);
          }}
        >
          <StyledTextInput
            placeholder={placeholder ? textForLocale(placeholder, locale) : ""}
            ref={inputRef}
            autoFocus
            onBlur={() => {
              setEditing(false);
              onCommit(id, value);
            }}
            value={textForLocale(value, locale) || ""}
            onChange={(e) => {
              const newValue = { ...value, [locale]: e.target.value };
              setValue(newValue);
            }}
          />
        </form>
      ) : (
        <Title onDoubleClick={() => setEditing(true)}>{textForLocale(value, locale)}</Title>
      )}
    </>
  );
};

export default EditableText;
