import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default async (flow: FlowIndexResponse, values: any, mutate: KeyedMutator<FlowIndexResponse>) => {
  return fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flow.id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      mutate();
      return response.json();
    })
    .catch((e) => {
      console.error(e);
      toast(
        <>
          <b>Error updating flow</b>
          <br />
          It was not possible to update this flow. Please try again later or contact support.
        </>
      );
    });
};
