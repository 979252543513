/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { FC } from "react";
import { PreviewInner } from "./StepCanvas.styles";
import { Cycle } from "grommet-icons";
import styled from "styled-components";

const AutomationStepInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: auto;
`;

interface RoleRotationStepProps {}

const RoleRotationStep: FC<RoleRotationStepProps> = () => {
  return (
    <PreviewInner>
      <AutomationStepInfo>
        <Cycle />
        <br />
        <small>Automation Step</small>
        The role rotation step that will rotate the roles forward.
        <br /> The flow will automatically proceed to the next step.
      </AutomationStepInfo>
    </PreviewInner>
  );
};

export default RoleRotationStep;
