import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default (
  mutate: KeyedMutator<FlowIndexResponse>,
  flowID: number,
  sectionID: number,
  stepID: number,
  locale: string
) => {
  fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flowID}/sections/${sectionID}/steps/${stepID}/audio/${locale}`, {
    method: "DELETE",
    credentials: "include",
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => {
      mutate();
    })
    .catch(() => {
      toast(
        <>
          <b>Unable to remove audio</b>
          <br />
          The server was not reachable, or returned an error. Please try again later or contact support.
        </>
      );
    });
};
