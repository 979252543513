// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { Grommet } from "grommet";
import WarmspaceTheme from "./theme";
import { ToastContainer } from "react-toastify";
import { Home as FlowBuilderHome } from "./components/FlowBuilder";
import FlowPreview from "./components/FlowPreview";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.min.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

const appElement = document.getElementById("app");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(appElement!);

root.render(
  <Grommet theme={WarmspaceTheme} style={{ height: "100vh", position: "relative" }}>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      limit={3}
      pauseOnHover
    />
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "40px",
              }}
            >
              <div>
                <b> Flow Builder </b>
                <br />
                <br />
                <b>404 - Page Not Found</b>
                <br />
                <br />
              </div>
              <div>
                <a href="https://schedule.warmspace.io">Back to schedule app</a>
              </div>
            </div>
          }
        />
        <Route path="/session-flow/:flowID" element={<FlowBuilderHome />} />
        <Route path="/session-flow/:flowID/section/:sectionID" element={<FlowBuilderHome />} />
        <Route path="/session-flow/:flowID/section/:sectionID/step/:stepID" element={<FlowBuilderHome />} />
        <Route path="/session-flow/:flowID/:locale/preview" element={<FlowPreview />} />
        <Route path="/session-flow/:flowID/section/:sectionID/:locale/preview" element={<FlowPreview />} />
        <Route path="/session-flow/:flowID/section/:sectionID/step/:stepID/:locale/preview" element={<FlowPreview />} />
      </Routes>
    </BrowserRouter>
  </Grommet>
);
